import styled from 'styled-components';

import { transparentize } from 'polished';

export const Container = styled.div`
  height: 170px;
  width: 250px;
  background-color: ${props => props.theme.card.background};
  border: 1px solid ${props => props.theme.card.border};
  border-radius: 5px;
  transition: ${props => props.theme.transition.hover};
`;

export const Header = styled.div`
  padding: 15px 15px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  border-radius: 5px;
  background-color: ${props =>
    props.color ? props.color : props.theme.primary};

  svg {
    font-size: 28px;
    color: ${props => props.theme.background} !important;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  strong {
    ${props => (props.skeleton ? 'width: 140px;' : null)};
    ${props => (props.skeleton ? 'height: 20px;' : null)};
    ${props => (props.skeleton ? 'margin-bottom: 2px;' : null)};
    font-size: 16px;
    font-weight: 600;
  }

  span {
    ${props => (props.skeleton ? 'width: 100px;' : null)};
    ${props => (props.skeleton ? 'height: 15px;' : null)};
    ${props => (props.skeleton ? 'margin: 2px 0;' : null)};
    font-size: 14px;
    color: ${props => transparentize(0.5, props.theme.light)};
  }
`;

export const Button = styled.div`
  display: flex;
  height: 45px;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  border-radius: 0 0 5px 5px;
  font-size: 14px;
  transition: ${props => props.theme.transition.hover};
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.primary};
    border-color: ${props => props.theme.primary};
    box-shadow: 0 1px 6px ${props => transparentize(0.7, props.theme.primary)};
  }
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.card.border};
`;
