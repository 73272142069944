import { withTheme } from 'styled-components';

import {
  Container,
  Header,
  Icon,
  Info,
  Button,
  Divider
} from './styles';

import { Pulse } from '../Skeleton';

const Simple = ({ theme }) => (
  <Container>
    <Header>
      <Icon color={theme.card.background}><Pulse /></Icon>
      <Info skeleton>
        <strong><Pulse /></strong>
        <span><Pulse /></span>
        <span><Pulse /></span>
      </Info>
    </Header>
    <Divider color={theme.card.border} />
    <Button>{'Ver Detalhes'}</Button>
  </Container>
);

export default withTheme(Simple);