import React from 'react';

import {
  Container,
  Divider,
  Header as HeaderContent,
  Subtitle,
  Title,
  BackButton,
} from './styles';

import { IoIosArrowBack } from 'react-icons/io';
import { useHistory } from 'react-router';

const Header = ({ title, subtitle, backButton = true, children }) => {
  const history = useHistory();

  return (
    <Container>
      <HeaderContent backButton={backButton}>
        {backButton && (
          <BackButton onClick={() => history.goBack()}>
            <IoIosArrowBack />
          </BackButton>
        )}
        <Title>{title}</Title>
      </HeaderContent>
      <Subtitle>{subtitle}</Subtitle>
      <Divider />
      {children}
    </Container>
  );
};

export default Header;
