import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: 0;
    font-family: 'Inter', sans-serif;
  }

  body {
    background-color: ${({ theme }) => theme.background};
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.light};
  }

  .dropdown-visible {
    animation-name: visible;
    animation-duration: .2s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
  }

  .dropdown-hidden {
    animation-name: hidden;
    animation-duration: .2s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
  }

  @keyframes visible {
    0% {
      transform: translateY(-10px);
      opacity: 0;
      visibility: hidden;
    }

    100% {
      transform: translateY(0px);
      opacity: 1;
      visibility: visible;
    }
  }
  
  @keyframes hidden {
    0% {
      transform: translateY(0px);
      opacity: 1;
      visibility: visible;
    }

    100% {
      transform: translateY(-10px);
      opacity: 0;
      visibility: hidden;
    }

  }
`;