import { lighten, transparentize } from 'polished';
import styled from 'styled-components';

import { Button as DefaultButton } from '../Button';

export const ModalContainer = styled.div`
  position: absolute;

  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0;
  visibility: hidden;

  overflow-x: hidden;

  width: 100vw;
  height: 100vh;

  background-color: ${props => transparentize(0.65, props.theme.dark)};

  transition: ${props => props.theme.transition.hover};
`;

export const ModalContent = styled.div`
  max-width: 35rem;

  background-color: ${props => props.theme.card.background};

  border: 1px solid ${props => props.theme.card.border};
  border-radius: 5px;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 15px 10px;

  border-bottom: 1px solid ${props => props.theme.card.border};

  font-size: 18px;
  color: ${props => props.theme.light};

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 5px;

    border: 1px solid transparent;
    border-radius: 8px;

    background-color: ${props => lighten(0.05, props.theme.card.background)};

    cursor: pointer;

    transition: ${props => props.theme.transition.hover};

    &:hover {
      border-color: ${props => props.theme.card.border};

      background-color: transparent;
    }
  }
`;

export const ModalChildren = styled.div`
  padding: 15px 0;
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  border-top: 1px solid ${props => props.theme.card.border};
`;

export const Button = styled(DefaultButton)`
  width: 100%;

  margin: 10px 15px;
`;
