import {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { breakText } from '../../utils';

import { Container, Content, Item, Selector } from './styles';

const Dropdown = forwardRef(
  (
    {
      behavior,
      values,
      placeholder,
      prefix = '',
      defaultValue,
      backgroundColor,
      borderColor,
      contentStyle,
      selectorStyle,
      onClick,
      reset,
      style,
      maxLength = 24,
    },
    ref,
  ) => {
    const [value, setValue] = useState(null);
    const [open, setOpen] = useState(false);

    const containerRef = useRef(null);
    const selectorRef = useRef(null);

    useEffect(() => {
      if (!behavior) {
        setValue(null);
        setOpen(false);
      }
    }, [behavior]);

    useEffect(() => {
      const handle = event => {
        if (
          containerRef.current &&
          selectorRef.current &&
          !containerRef.current.contains(event.target) &&
          open
        )
          handleDropdown(true);
      };

      document.addEventListener('mousedown', handle);

      return () => document.removeEventListener('mousedown', handle);
    }, [containerRef, open]);

    const handleDropdown = mode => {
      if (!selectorRef) return;

      if (mode) {
        selectorRef.current.classList.remove('dropdown-visible');
        selectorRef.current.classList.add('dropdown-hidden');
      } else {
        selectorRef.current.classList.add('dropdown-visible');
        selectorRef.current.classList.remove('dropdown-hidden');
      }

      setOpen(!mode);
    };

    useImperativeHandle(ref, () => ({
      getValue: () => value,
      setValue,
    }));

    const getText = () => {
      if (defaultValue && !value) {
        return <strong>{breakText(defaultValue, maxLength)}</strong>;
      } else if (value && !reset) {
        return (
          <strong>
            {breakText(
              prefix + typeof value === 'string' ? value : value.name,
              maxLength,
            )}
          </strong>
        );
      } else {
        return <span>{placeholder || 'Escolha uma opção...'}</span>;
      }
    };

    return (
      <Container ref={containerRef} style={style}>
        <Content
          style={contentStyle}
          onClick={() => handleDropdown(open)}
          backgroundColor={backgroundColor}
          borderColor={borderColor}
        >
          {getText()}
        </Content>
        <Selector style={selectorStyle} ref={selectorRef}>
          {values.map((item, index) => (
            <Item
              key={index}
              onClick={() => {
                handleDropdown(true);
                setValue(item);
                if (onClick) {
                  onClick(typeof item === 'string' ? index : item);
                }
              }}
            >
              {typeof item === 'string' ? item : item.name}
            </Item>
          ))}
        </Selector>
      </Container>
    );
  },
);

export default Dropdown;
