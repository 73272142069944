import React, { useEffect, useState } from 'react';

import { useHistory, Link } from 'react-router-dom';

import {
  Container,
  LogoContainer,
  LoginContainer,
  LoginTitle,
  Field,
  FieldText,
  Input,
  Icon,
  Error,
} from './styles';

import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { MdDone } from 'react-icons/md';
import { BiErrorCircle } from 'react-icons/bi';

import Loader from '../../components/Loader';
import { Button } from '../../components/Button';
import LogoSvg from '../../assets/logo-teste-branco.svg';

import { auth } from '../../services';

const validateEmail = email => {
  const validation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return validation.test(String(email).toLowerCase());
}

const validatePassword = password => password.length >= 6;

const Login = () => {
  document.title = 'Login – Progy';
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [viewPassword, setViewPassword] = useState(false);
  const [buttonBlock, setButtonBlock] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [send, setSend] = useState(false);

  useEffect(() => {
    let element = document.getElementById('input-password');
    if (element) viewPassword ? element.type = 'text' : element.type = 'password';
  }, [viewPassword]);

  useEffect(() => {
    email !== '' &&
      password !== '' &&
      validateEmail(email) &&
      validatePassword(password) ? setButtonBlock(false) : setButtonBlock(true);
  }, [email, password]);

  const handleLogin = () => {
    if (!buttonBlock) {
      setSend(false);
      setLoading(true);

      auth.login(email, password).then(response => {
        const { status, token, error } = response;
        setLoading(false);

        if (error) {
          setError('Houve um erro em processar sua solicitação.');
        } else {
          if (status === 404) {
            setError('Sua conta não foi encontrada.');
          } else if (status === 401) {
            setError('E-mail ou senha incorretos.');
          } else if (status === 500) {
            setError('Houve um erro em processar sua solicitação.');
          } else if (status === 200) {
            setError('');
            setSend(true);

            history.push({
              pathname: '/',
              state: { token }
            });
          }
        }
      });
    }
  }

  return <Container>
    <a href="https://progy.com.br">
      <LogoContainer>
        <img src={LogoSvg} alt="PROGY" />
        <span>PROGY</span>
      </LogoContainer>
    </a>
    <LoginContainer>
      <LoginTitle>
        <span>ACESSO À PLATAFORMA</span>
        <strong>Faça login na sua conta</strong>
      </LoginTitle>
      <Field>
        <FieldText>
          <span>E-mail</span>
          <span></span>
        </FieldText>
        <Input>
          <input
            placeholder="Digite seu e-mail"
            type="text"
            onChange={event => {
              setEmail(event.target.value)
            }}
          />
          <Icon show={email !== ''} error={!validateEmail(email)}>
            {validateEmail(email) ? <MdDone /> : 'X'}
          </Icon>
        </Input>
      </Field>
      <Field>
        <FieldText>
          <span>Senha</span>
          <Link to="/forgot">
            <span>Esqueceu a senha?</span>
          </Link>
        </FieldText>
        <Input>
          <input
            id="input-password"
            placeholder="Digite sua senha"
            type="password"
            onKeyDown={(event) => event.key === 'Enter' ? handleLogin() : null}
            onChange={(event) => setPassword(event.target.value)}
          />
          {!viewPassword ? <AiOutlineEye
            onClick={() => setViewPassword(!viewPassword)}
          /> : <AiOutlineEyeInvisible onClick={() => setViewPassword(!viewPassword)} />}
        </Input>
      </Field>
      <Error show={error !== ''}>
        <BiErrorCircle />
        {error}
      </Error>
      <Button
        disabled={buttonBlock}
        onClick={handleLogin}
      >
        {!send && (loading ? <Loader scale={.5} /> : 'Acessar')}
        {send ? <MdDone style={{ fontSize: 30 }} /> : null}
      </Button>

    </LoginContainer>
  </Container>
}

export default Login;