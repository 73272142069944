import { darken, transparentize } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${props => transparentize(.8, props.theme.light)};
  border-radius: 5px;
`;

export const Item = styled.div`
  padding: 5px;
  cursor: ${props => props.block ? 'not-allowed' : 'pointer'};
  background-color: ${props => props.block ? darken(.03, props.theme.card.background) : 'unset'};
  background-color: ${props => props.active && transparentize(.75, props.theme.primary)};
  font-size: 16px;
  transition: ${props => props.theme.transition.hover};

  svg {
    font-size: 14px;
  }

  span {
    padding: 5px 7px;
  }

  &:hover {
    background-color: ${props => !props.block && transparentize(.8, props.theme.light)};
  }

  &:not(:last-child) {
    border-right: 1px solid ${props => transparentize(.8, props.theme.light)};
  }

  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
`;