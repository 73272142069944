import React from 'react';

import Header from '../../components/Header';
import cookies from 'js-cookie';

import {
  CardContainer,
  CardContent,
  Container,
  CardIcon,
  CardButton,
  Content,
} from './styles';

import { BiEdit } from 'react-icons/bi';
import { RiFileEditLine, RiFileEditFill } from 'react-icons/ri';
import { useHistory, useParams } from 'react-router';

const Management = () => {
  document.title = 'Gerenciamento – Progy';
  const history = useHistory();
  const { id } = useParams();

  const options = [
    {
      icon: <BiEdit />,
      title: 'Editar Parametrização',
      description:
        'Realize a edição das parametrizações de um planejamento específico.',
      onClick: () => history.push(`/management/${id}/params`),
      buttonText: 'Editar',
    },
    {
      icon: <RiFileEditLine />,
      title: 'Gerir Iniciativas',
      description:
        'Veja todas as iniciativas de um planejamento e realize edições ou adições.',
      onClick: () => history.push(`/management/${id}/initiatives`),
      buttonText: 'Gerir',
    },
    {
      icon: <RiFileEditFill />,
      title: 'Gerir Atividades',
      description:
        'Veja todas as atividades de um planejamento e realize edições ou adições.',
      onClick: () => history.push(`/management/${id}/activities`),
      buttonText: 'Gerir',
    },
  ];

  return (
    <Header
      title="Gerenciamento"
      subtitle="Adicione um novo planejamento ou edite um existente."
    >
      <Container>
        <Content>
          {options.map((item, index) => {
            if (item.title.includes('Nova') && cookies.get('role') !== 'admin')
              return null;

            return (
              <CardContainer key={index}>
                <CardIcon>{item.icon}</CardIcon>
                <CardContent>
                  <strong>{item.title}</strong>
                  <span>{item.description}</span>
                </CardContent>
                <CardButton onClick={item.onClick}>
                  {item.buttonText}
                </CardButton>
              </CardContainer>
            );
          })}
        </Content>
      </Container>
    </Header>
  );
};

export default Management;
