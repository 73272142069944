import styled from 'styled-components';

import { default as DefaultSelect } from 'react-select';
import { darken, tint, transparentize } from 'polished';

export const ReactSelect = styled(DefaultSelect)`
  margin: 0.5rem 0 2rem 0;

  height: 1.375rem;
  max-width: 31.3rem;

  .react-select__control {
    background-color: ${props =>
      darken(0.01, props.theme.card.background)} !important;

    border: 1px solid
      ${props => (props.error ? props.theme.error : props.theme.card.border)} !important;
    border-radius: 0.3125rem;

    cursor: pointer;
  }

  .react-select__input-container {
    color: ${props => props.theme.light} !important;
  }

  .react-select__single-value {
    color: ${props => props.theme.light} !important;
  }

  .react-select__control--is-focused {
    box-shadow: 0 0 0 1px ${props => transparentize(0.3, props.theme.light)};
  }

  .react-select__menu {
    margin-top: 1.5rem;

    background-color: ${props => darken(0.01, props.theme.card.background)};
    box-shadow: 0 1px 6px ${props => transparentize(0.6, props.theme.dark)};

    color: ${props => props.theme.light};
  }

  .react-select__option--is-focused {
    background-color: ${props =>
      props.theme.tile === 'dark'
        ? tint(0.1, props.theme.background)
        : darken(0.05, props.theme.background)} !important;

    cursor: pointer;
  }

  .react-select__option--is-selected {
    background-color: ${props =>
      props.theme.tile === 'dark'
        ? tint(0.1, props.theme.background)
        : darken(0.05, props.theme.background)} !important;

    color: ${props =>
      props.theme.title === 'light' && props.theme.light} !important;
  }

  @media (max-width: 768px) {
    max-width: unset;
  }
`;

export const ErrorContainer = styled.div`
  margin: 0.25rem 0 0.5rem 0;

  span {
    color: ${props => props.theme.error};
  }
`;
