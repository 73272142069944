import React, { useRef, useEffect } from 'react';

import { useField } from '@unform/core';
import { components } from 'react-select';

import { ReactSelect, ErrorContainer } from './styles';

const Select = ({ name, emptyText, options, ...rest }) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'state.value',
      getValue: ref => {
        const values = ref.state.selectValue;
        if (values.length === 0) {
          return null;
        }

        return ref.state.selectValue[0].value;
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>{emptyText}</span>
      </components.NoOptionsMessage>
    );
  };

  return (
    <div>
      <ReactSelect
        ref={selectRef}
        defaultValue={
          defaultValue && options.find(option => option.value === defaultValue)
        }
        components={{ NoOptionsMessage }}
        classNamePrefix="react-select"
        options={options}
        error={error}
        {...rest}
      />
      <ErrorContainer>
        <span>{error}</span>
      </ErrorContainer>
    </div>
  );
};
export default Select;
