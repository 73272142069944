import React, { useEffect, useRef, useState } from 'react';

import { Form } from '@unform/web';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  CardBody,
  CardContainer,
  CardFooter,
  CardHeader,
  Container,
  Button,
} from './styles';

import Header from '../../components/Header';
import { Input } from '../../components/Form';

import api from '../../services/new-api';

const roles = {
  admin: 'Administrador',
  subadmin: 'Gestor',
  manager: 'Secretário',
  user: 'Técnico',
};

const Settings = () => {
  document.title = 'Configurações – Progy';

  const personalRef = useRef(null);
  const changePasswordRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  const headerProps = {
    title: 'Configurações',
    subtitle: 'Visualize e edite as configurações da sua conta e planejamentos',
    backButton: false,
  };

  const toastProps = {
    autoClose: 2500,
  };

  useEffect(() => {
    const fetchUser = async () => {
      const { data, error } = await api.get({ route: 'users/me ' });

      if (!error) {
        setUser(data);

        const formatData = { ...data, role: roles[data.role] };
        personalRef.current?.setData(formatData);

        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  const defaultSubmit = async (ref, schema, data) => {
    try {
      await schema.validate(data, { abortEarly: false });

      ref.current?.setErrors({});

      return true;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};

        error.inner.forEach(err => {
          errorMessages[err.path] = err.message;
        });

        ref.current?.setErrors(errorMessages);
      }
      return false;
    }
  };

  const getToastProps = () => {
    const pending = 'Atualizando...';
    const success = 'Informações atualizadas com sucesso';
    const error = 'Houve um erro ao atualizar suas informações';

    return { pending, success, error };
  };

  const UserInfo = () => {
    if (loading) {
      return <div />;
    }

    const ref = personalRef;

    const title = 'Informações pessoais';
    const subtitle = 'Visualize e edite suas informações pessoais';

    const schema = Yup.object().shape({
      name: Yup.string().required('O nome é obrigatório'),
    });

    const handleSubmit = async data => {
      const response = await defaultSubmit(ref, schema, data);
      const body = { name: data.name };

      if (response) {
        const promise = new Promise(async (resolve, reject) => {
          const { error } = api.put({ route: `users/${user.id}`, body });

          if (error) {
            reject();
          }

          resolve();
        });
        toast.promise(promise, getToastProps());
      }
    };

    const getFormInitialData = () => {
      let initialData = { ...user };

      if (user && user.role) {
        initialData = { ...initialData, role: roles[user.role] };
      }

      return initialData;
    };

    return (
      <CardContainer>
        <CardHeader>
          <h3>{title}</h3>
          <span>{subtitle}</span>
        </CardHeader>
        <Form
          ref={ref}
          onSubmit={handleSubmit}
          initialData={getFormInitialData()}
        >
          <CardBody>
            <span>Nome</span>
            <Input name="name" />

            <span>E-mail</span>
            <Input name="email" disabled />

            <span>Cargo</span>
            <Input name="role" disabled />
          </CardBody>
          <CardFooter>
            <Button onClick={() => ref.current?.submitForm()}>Atualizar</Button>
          </CardFooter>
        </Form>
      </CardContainer>
    );
  };

  const ChangePassword = () => {
    const ref = changePasswordRef;

    const title = 'Senha';
    const subtitle = 'Altere sua senha';

    const schema = Yup.object().shape({
      currentPassword: Yup.string().required('A senha atual é obrigatória'),
      newPassword: Yup.string()
        .min(6, 'A nova senha precisa ter ao menos 6 caracteres')
        .required('A nova senha é obrigatória'),
    });

    const handleSubmit = async data => {
      const response = await defaultSubmit(ref, schema, data);

      if (response) {
        const promise = new Promise(async (resolve, reject) => {
          const { error } = await api.post({
            route: 'users/change-password',
            body: data,
          });

          if (error) {
            reject();
          }

          resolve();
        });
        toast.promise(promise, getToastProps());
      }
    };

    return (
      <CardContainer>
        <CardHeader>
          <h3>{title}</h3>
          <span>{subtitle}</span>
        </CardHeader>
        <Form ref={ref} onSubmit={handleSubmit}>
          <CardBody>
            <span>Senha atual</span>
            <Input name="currentPassword" type="password" />

            <span>Nova senha</span>
            <Input name="newPassword" type="password" />
          </CardBody>
          <CardFooter>
            <Button onClick={() => ref.current?.submitForm()}>Atualizar</Button>
          </CardFooter>
        </Form>
      </CardContainer>
    );
  };

  return (
    <Header {...headerProps}>
      <ToastContainer {...toastProps} />
      <Container>
        <UserInfo />
        <ChangePassword />
      </Container>
    </Header>
  );
};

export default Settings;
