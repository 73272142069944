import styled from 'styled-components';

import { darken, transparentize } from 'polished';

import { Button as DefaultButton } from '../../../components/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`;

export const StepContainer = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
`;

export const StepItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: 1px solid ${props => props.active ? props.theme.primary : props.theme.card.border};
  background-color: ${props => props.active ? props.theme.primary : 'transparent'};
  color: ${props => props.active ? props.theme.light : props.theme.card.border};
  border-radius: 10px;
  transition: ${props => props.theme.transition.hover};
`;

export const StepDivider = styled.div`
  &:not(:last-child) {
    width: 15px;
    height: 1px;
    background-color: ${props => props.active ? props.theme.primary : props.theme.card.border};
    transition: ${props => props.theme.transition.hover};
  }
`;

export const FormContainer = styled.div`
  background-color: ${props => props.theme.card.background};
  border: 1px solid ${props => props.theme.card.border};
  border-radius: 5px;
  padding: 25px 30px;
  margin-bottom: 25px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  strong {
    font-size: 22px;
    font-weight: 600;
    text-transform: capitalize;
  }

  span {
    color: ${props => transparentize(.5, props.theme.light)};
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
  }
`;

export const FormContent = styled.div`
  padding: 30px 0 0;
  display: flex;
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  margin-bottom: 20px;
`;

export const FieldText = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  
  span {
    color: ${({ theme }) => theme.light};
  }

  span:last-child {
    font-weight: 300;
    opacity: .5;
    font-size: 14px;
    cursor: pointer;
    transition: ${props => props.theme.transition.hover};
  }

  span:last-child:hover {
    opacity: 1;
  }
`;

export const Input = styled.div`
  padding: 10px;
  height: 22px;
  border: 1px solid ${props => props.theme.card.border};
  background-color: ${props => darken(.01, props.theme.card.background)};
  border-radius: 5px;
  transition: ${props => props.theme.transition.hover};
  margin-top: 10px;
  display: flex;
  align-items: center;

  input {
    width: 90%;
    background-color: transparent;
    border: none;
    color: ${props => props.theme.light};
    font-size: 16px;

    &:hover, &:focus {
      border: none;
      outline: none;
    }

    &::-webkit-input-placeholder {
      font-size: 15px;
    }
  }

  svg {
    font-size: 24px;
    color: ${props => props.theme.sidebar.border};
    cursor: pointer;
    transition: ${props => props.theme.transition.hover};

    &:hover {
      color: ${props => props.theme.light};
    }
  }

  &:hover {
    box-shadow: 0 1px 6px ${props => transparentize(.8, props.theme.light)};
    border-color: ${props => transparentize(.3, props.theme.light)};
  }
`;

export const Icon = styled.div`
  margin-top: 10px;
  padding: 12px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => props.theme.card.border};
  background-color: ${props => darken(.01, props.theme.card.background)};
  cursor: pointer;
  transition: ${props => props.theme.transition.hover};

  svg {
    font-size: 18px;
    color: ${props => props.theme.light};
  }

  &:hover {
    box-shadow: 0 1px 6px ${props => transparentize(.8, props.theme.light)};
    border-color: ${props => transparentize(.3, props.theme.light)};
  }
`;

export const Table = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 30px 0;
  max-height: 150px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  & table {
    border-collapse: collapse;
    table-layout: fixed;
    border: 1px solid transparent;
    transition: ${props => props.theme.transition.hover};

    & th, td {
      text-align: center;
      border-bottom: 1px solid ${props => transparentize(.95, props.theme.light)};
      padding: 5px 5px;
      transition: ${props => props.theme.transition.hover};
    }

    & tr:nth-child(even) {
      background-color: ${props => transparentize(.85, props.theme.background)};
      border-radius: 5px;
    }

    & th {
      font-weight: 400;
    }

    & td {
      font-size: 14px;
      padding: 10px 5px;
      color: ${props => transparentize(.5, props.theme.light)}; 
      overflow: hidden;
      width: 200px;

      & svg {
        font-size: 16px;
        transition: ${props => props.theme.transition.hover};
        cursor: pointer;

        &:hover {
          color: ${props => props.theme.light};
        }
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Button = styled(DefaultButton)`
  width: 150px;
  margin-top: 10px;
  margin-bottom: 0;

  @media (max-width: 768px) {
    width: 100%;

    &:first-child {
      margin-top: 20px;  
    }
  }
`;