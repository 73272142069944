const light = {
  title: 'light',
  logoFile: 'logo-black',
  background: 'rgb(255, 255, 255)',
  primary: 'rgb(64, 139, 255)',
  light: 'rgb(20, 20, 25)',
  dark: 'rgb(0, 0, 0)',
  error: '#d84a44',
  success: '#2eb82e',

  login: {
    background: {
      start: 'rgba(235, 235, 230, 1)',
      end: 'rgba(221, 221, 213, 1)',
    },
  },

  button: {
    text: '#fff',
  },

  sidebar: {
    background: 'rgb(245, 245, 245)',
    border: 'rgba(221, 221, 213, 1)',
    item: '#141419',
    width: 70,
  },

  card: {
    background: '#fff',
    border: 'rgba(221, 221, 213, 1)',
  },

  colors: {
    background: 'rgb(235, 235, 230)',
  },

  transition: {
    hover: '.2s ease',
  },

  cards: {
    goals: '#33bbff',
    project: '#da9b65',
    done: '#22cc62',
    problems: '#E52E2E',
  },

  status: {
    done: '#53ff1a',
    pending: '#ffcc00',
  },

  notification: {
    done: {
      background: '#e5effa',
    },
    error: {
      primary: '#eb4e2d',
      backgorund: '#fcedea',
    },
  },
};

export default light;
