import styled from 'styled-components';

import { transparentize, darken } from 'polished';

export const Container = styled.div`
  margin-left: 0.5rem;

  position: relative;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props => darken(0.01, props.theme.card.background)};
  border: 1px solid ${props => props.theme.card.border};
  border-radius: 5px;
  transition: ${props => props.theme.transition.hover};

  &:hover,
  &:focus-within {
    box-shadow: 0 1px 6px ${props => transparentize(0.8, props.theme.light)};
    border-color: ${props => transparentize(0.3, props.theme.light)};

    svg {
      color: ${props => props.theme.light};
    }
  }

  @media (max-width: 768px) {
    margin: 10px 0;
    width: unset;
  }
`;

export const Input = styled.input`
  border: none;
  padding: 5px;
  background-color: transparent;
  color: ${props => props.theme.light};
  height: 30px;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin: 0 5px;
  background-color: ${props => darken(0.05, props.theme.card.background)};
  border: 1px solid ${props => props.theme.card.border};
  border-radius: 5px;
  transition: ${props => props.theme.transition.hover};

  svg {
    color: ${props => transparentize(0.4, props.theme.light)};
    transition: ${props => props.theme.transition.hover};
  }
`;
