import { useState, useEffect } from 'react';

import { Content, Container, Icon, Input } from './styles';

import { BiSearchAlt } from 'react-icons/bi';

const BasicInput = ({ placeholder, search, setSearch }) => {
  const [delay, setDelay] = useState(null);

  const timeoutTime = 500;

  useEffect(() => {
    return () => {
      clearTimeout(delay);
    };
  }, [delay]);

  const handleInput = event => {
    if (delay) {
      clearTimeout(delay);
    }

    const timeout = setTimeout(() => {
      setSearch(event.target.value);
    }, timeoutTime);

    setDelay(timeout);
  };

  return (
    <Container>
      <Content>
        <Icon>
          <BiSearchAlt />
        </Icon>
        <Input
          defaultValue={search}
          placeholder={placeholder || ''}
          onChange={handleInput}
        />
      </Content>
    </Container>
  );
};

export default BasicInput;
