import styled from 'styled-components';

export const Container = styled.div`  
  padding: 0px 0 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    margin-top: 20px;
    text-align: center;
  }
`;

export const ResultContainer = styled.div`
  padding: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.error ? props.theme.error : props.theme.primary};
  box-shadow: 0 1px 6px ${props => props.error ? props.theme.error : props.theme.primary};

  svg {
    font-size: 50px;
    color: ${props => props.theme.light};
  }
`;

export const Loader = styled.div`
  width: 80px;
  height: 80px;
  div {
    animation: loaderAnimation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
    box-shadow: 0 1px 6px white;
    &:after {
      content: " ";
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: ${props => props.theme.primary};
      margin: -4px 0 0 -4px;
    }
    &:nth-child(1) {
      animation-delay: -0.036s;
    }
    &:nth-child(1):after {
      top: 63px;
      left: 63px;
    }
    &:nth-child(2) {
      animation-delay: -0.072s;
    }
    &:nth-child(2):after {
      top: 68px;
      left: 56px;
    }
    &:nth-child(3) {
      animation-delay: -0.108s;
    }
    &:nth-child(3):after {
      top: 71px;
      left: 48px;
    }
    &:nth-child(4) {
      animation-delay: -0.144s;
    }
    &:nth-child(4):after {
      top: 72px;
      left: 40px;
    }
    &:nth-child(5) {
      animation-delay: -0.18s;
    }
    &:nth-child(5):after {
      top: 71px;
      left: 32px;
    }
    &:nth-child(6) {
      animation-delay: -0.216s;
    }
    &:nth-child(6):after {
      top: 68px;
      left: 24px;
    }
    &:nth-child(7) {
      animation-delay: -0.252s;
    }
    &:nth-child(7):after {
      top: 63px;
      left: 17px;
    }
    &:nth-child(8) {
      animation-delay: -0.288s;
    }
    &:nth-child(8):after {
      top: 56px;
      left: 12px;
    }
  }
  @keyframes loaderAnimation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;