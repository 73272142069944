import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import {
  ModalContainer,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Button,
  ModalChildren,
} from './styles';

import { VscChromeClose } from 'react-icons/vsc';
import { api } from '../../services';
import Loader from '../Loader';

const Modal = forwardRef(({ title, deleteUrl, onClose, onDone, onDelete, disableButton = true, children }, ref) => {
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState(null);
  const [type, setType] = useState('add'); // add, modify

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [doneLoading, setDoneLoading] = useState(false);

  useEffect(() => {
    const keyListener = event => {
      if (event.key === 'Escape') {
        handle('close');
      }
    }
    
    document.addEventListener('keydown', keyListener);

    return () => {
      document.removeEventListener('keydown', keyListener);
    }
    //eslint-disable-next-line
  }, []);

  const handle = behavior => {
    let element = document.getElementById('modal');

    const update = type => {
      element.style.visibility = type ? 'visible' : 'hidden';
      element.style.opacity = type ? 1 : 0;
    }

    if (element) {
      let type = open;
      
      if (behavior) {
        type = behavior === 'close' ? true : false;
      }

      update(!type);
    }

    setOpen(!open);
  }

  const setValue = (item, type) => {
    if (type === 'modify') {
      setItem(item);
    }

    setType(type);
    handle();
  }

  useImperativeHandle(ref, () => ({
    handle,
    getState: () => open,
    getItem: () => item,
    setItem,
    setValue,
    getType: () => type,
  }));

  const defaultValues = ['Concluído']; // Default values ​​that cannot be deleted
  
  const getTitle = () => type === 'add' ? 'Adicionar' : 'Modificar';

  const buttonDisabled = rule => {
    if (deleteLoading || doneLoading) {
      return true;
    }

    return rule;
  }

  return (
    <ModalContainer id="modal">
      <ModalContent>
        <ModalHeader>
          <span>
            {`${getTitle()} ${title}`}
          </span>
          <div onClick={() => {
            handle();
            setTimeout(onClose, 1.5 * 100);
          }}>
            <VscChromeClose />
          </div>
        </ModalHeader>
        <ModalChildren>
          {children}
        </ModalChildren>
        <ModalFooter>
          <Button color="#d84a44" disabled={buttonDisabled(type === 'add')} onClick={() => {
            if (!buttonDisabled(type !== 'add')) {
              return;
            }

            setDeleteLoading(true);

            if (defaultValues.filter(value => value === item.name).length > 0) {
              setDeleteLoading(false);
              handle();
              onDelete({ code: null, error: 'Cannot delete default value'});
              return;
            }

            (async () => {
              await api.request(`${deleteUrl}/${item.id}`, 'DELETE');

              setDeleteLoading(false);

              handle();
              onDelete(false);
            })();
          }}>{deleteLoading ? <Loader scale={.5} /> : 'Excluir'}</Button>
          <Button disabled={buttonDisabled(disableButton)} onClick={() => {
            if (buttonDisabled(disableButton)) {
              return;
            }

            setDoneLoading(true);

            (async () => {
              await onDone();
              setDoneLoading(false);
              handle();
            })();
          }}>{doneLoading ? <Loader scale={.5} /> : getTitle()}</Button>
        </ModalFooter>
      </ModalContent>
    </ModalContainer>
  )
});

export default Modal;