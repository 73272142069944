import React, { createContext, useContext, useState } from 'react';
import { ThemeProvider } from 'styled-components';

import { light } from '../styles/themes';

const CustomThemeContext = createContext({});

const CustomThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState({ ...light });

  const generateNewStyles = colors => {
    let newTheme = { ...theme };

    for (let item in colors) {
      if (typeof colors[item] === 'object') {
        for (let i in colors[item]) {
          newTheme[item][i] = colors[item][i];
        }
      } else {
        newTheme[item] = colors[item];
      }
    }

    setTheme(newTheme);
  };

  const resetStyles = () => {
    const theme = { ...light };

    // theme.sidebar.background = 'rgb(20, 20, 25)';
    // theme.sidebar.border = 'rgb(49,51,53)';
    // theme.sidebar.item = '#fff';
    // theme.card.background = 'rgb(48, 50, 56)';
    // theme.card.border = 'rgb(67, 69, 72)';

    setTheme(theme);
  };

  const values = {
    generateNewStyles,
    resetStyles,
    theme,
  };

  return (
    <ThemeProvider theme={theme}>
      <CustomThemeContext.Provider value={values}>
        {children}
      </CustomThemeContext.Provider>
    </ThemeProvider>
  );
};

const useCustomTheme = () => useContext(CustomThemeContext);

export { CustomThemeContext, CustomThemeProvider, useCustomTheme };
