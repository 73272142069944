import cookie from 'js-cookie';

import { expires } from '../utils';
import api from './new-api';

const auth = {
  login: async (email, password) => {
    try {
      const body = { email, password };

      const response = await fetch(api.getHost('login'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
      });

      const { token, role } = await response.json();
  
      cookie.set('token', token, expires);
      cookie.set('role', role, expires);
  
      return { status: response.status, token };
    } catch (error) {
      return { error: error.message };
    }
  },
  logout: () => {
    cookie.remove('token');
    cookie.remove('role');
  }
}

export default auth;