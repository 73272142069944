import React, { createRef, Fragment, useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router';
import { withTheme } from 'styled-components';

import moment from 'moment';
import NumberFormat from 'react-number-format';
import Dropzone from 'react-dropzone';
import {
  add,
  format,
  fromUnixTime,
  getUnixTime,
  isValid,
  parseISO,
} from 'date-fns';

import {
  NotificationContainer,
  NotificationIcon,
  OptionsContainer,
  TableContainer,
  Button,
  Item,
  // PanelContainer,
  // PanelValue,
  // PanelIcon,
  FilterContainer,
  // PaginationContainer,
  EmptyItem,
  FieldContent,
  Dragdrop,
  Field,
  Input,
  FieldText,
  DownloadIcon,
  ReactSelect,
  ListContainer,
  TotalValueCard,
} from './styles';

import Header from '../../../../components/Header';
import Modal from '../../../../components/Modal';
// import Pagination from '../../../../components/Pagination';
import Dropdown from '../../../../components/Dropdown';
import Search from './Search';
import { Pulse } from '../../../../components/Skeleton';

import { api } from '../../../../services';
import { base64toBlob, capitalize } from '../../../../utils';
import parameters from './parameters';

import { MdDone } from 'react-icons/md';
import { IoAlert } from 'react-icons/io5';
import { BiEdit } from 'react-icons/bi';
// import { FaRegMoneyBillAlt } from 'react-icons/fa';
import { FiInbox } from 'react-icons/fi';
import { BsCloudDownload } from 'react-icons/bs';

import { components } from 'react-select';

import 'moment/locale/pt-br';
moment.locale('pt-br');

const Editable = ({ location, theme }) => {
  document.title = 'Gerenciamento – Progy';
  const modalRef = createRef();

  const { id, param } = useParams();
  const history = useHistory();

  const [data, setData] = useState(Array(3).fill(3));
  const [associations, setAssociations] = useState([]);
  const [temp, setTemp] = useState(null);
  const [loading, setLoading] = useState(true);
  const [buttonState, setButtonState] = useState(false);

  const [dropdownBehavior, setDropdownBehavior] = useState(false);

  const [currentPage] = useState(1);
  const [, setTotalItems] = useState(0);

  const [requestLoading, setRequestLoading] = useState(false);
  const [error, setError] = useState(true);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationType, setNotificationType] = useState(null);

  const [filter, setFilter] = useState(
    location.state ? location.state.filter : null,
  );
  const [search, setSearch] = useState('');

  const [totalValue, setTotalValue] = useState(0);

  const maxItemsPerPage = 10;
  // const maxChunckSize = 3;

  const excludeParameterTable = [
    'Arquivo',
    'Observação',
    'Objetivo',
    'Perspectiva',
    'Tipo de MAPP',
    'Código Orçamentário',
  ];

  if (isNaN(id)) history.push('/management');

  const fetchData = () => {
    setLoading(true);

    let fields = '';

    getEntity().fields.forEach(item => {
      if (item.field.includes('_id')) {
        fields += `${item.field.substring(0, item.field.length - 3)},`;
      }
    });

    let url = `plannings/${id}/${param}?`;
    if (getEntity().view)
      url = `plannings/${id}/${getEntity().baseroute}/${param}?`;
    if (fields) url += `populate=${fields}`;
    if (filter) {
      const filterObject = Object.keys(filter);
      if (filterObject.filter(item => filter[item] !== 0).length > 0) {
        let object = {};

        filterObject.forEach(item => {
          if (filter[item] !== 0) {
            object[item] = filter[item];
          }
        });

        url += `${!url.includes('&') && '&'}filter=${JSON.stringify(object)}`;
      }
    }

    if (getEntity().title === 'Iniciativas') {
      url += `&pagination=${JSON.stringify({
        perPage: maxItemsPerPage,
        page: currentPage - 1,
      })}`;
      url += `&metrics=true`;
    }

    if (search !== '') {
      let codeQuery =
        getEntity().title === 'Iniciativas' ? 'code' : 'initiativeCode';

      url += `&${codeQuery}=${search}`;
    }

    api
      .request(url, 'GET')
      .then(response => {
        const { data, status, error } = response;

        if (status !== 200 || error) {
          setData([]);
          return;
        }

        let sorted = data;
        if (sorted instanceof Array) {
          sorted.sort((a, b) => {
            if (!Object.keys(a).includes('date_start')) return 1;

            return moment(a.date_start).diff(b.date_start, 'days');
          });
        }

        setTotalItems(response.total);
        setData(sorted);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setNullTemp();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchData();

    let promises = [];
    getEntity().fields.forEach(item => {
      if (item.field.includes('_id')) {
        promises.push(api.request(`plannings/${id}/${item.route}`, 'GET'));
      }
    });

    Promise.all(promises).then(responses => {
      const error =
        responses.map(item => item.status).filter(item => item !== 200).length >
        0;

      if (!error) {
        let body = [];
        responses.forEach((response, index) => {
          let list = getEntity().fields.filter(item =>
            item.field.includes('_id'),
          );

          body = [...body, { [list[index].field]: response.data }];
        });

        setAssociations(body);
      }
    });
    //eslint-disable-next-line
  }, [id, param]);

  useEffect(() => {
    fetchData();
    //eslint-disable-next-line
  }, [filter, search]);

  useEffect(() => {
    if (!filter && search === '') {
      return;
    }

    let value = 0;
    const key = getEntity().route === 'initiatives' ? 'totalValue' : 'value';

    data.forEach(item => {
      value += Number(item[key] || 0);
    });

    setTotalValue(value);
    // eslint-disable-next-line
  }, [filter, search, data]);

  useEffect(() => {
    if (data && temp) {
      let pass = true;

      if (modalRef.current?.getType() === 'add') {
        pass = false;
        getEntity().fields.forEach(item => {
          if (temp[item.field] === '' && !item.allowNull) pass = true;
        });
      } else {
        Object.keys(temp).forEach(key =>
          data.forEach(item => {
            if (temp.id === item.id) {
              if (temp[key] !== item[key]) pass = false;
              if (temp[key] === '') pass = true;
              if (item.allowNull) pass = false;
            }
          }),
        );
      }

      if (!pass) {
        let date = { start: undefined, end: undefined };

        getEntity().fields.forEach(item => {
          if (item.field.includes('date_')) {
            const key = item.field.replace('date_', '');
            const time = temp[item.field].split('-');
            date[key] = new Date(time[0], time[1] - 1, time[2]);
          }
        });

        if (
          date.end &&
          date.start &&
          ![date.end.toString(), date.start.toString()].includes('Invalid Date')
        ) {
          const start = date.start.getTime();
          const end = date.end.getTime();

          pass = start > end;
        }
      }

      setButtonState(pass);
    }
    //eslint-disable-next-line
  }, [data, temp]);

  useEffect(() => {
    if (!requestLoading && temp) {
      setData(new Array(3).fill(3));
      setNullTemp();
      setLoading(true);
      fetchData();
    }
    //eslint-disable-next-line
  }, [requestLoading]);

  useEffect(() => {
    setLoading(true);
    fetchData();

    //eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    let timer;
    if (notificationOpen) timer = setTimeout(() => handleNotification(), 4000);

    return () => {
      if (timer) clearTimeout(timer);
    };
    //eslint-disable-next-line
  }, [notificationOpen]);

  const getTitle = (singular = false) => {
    const title = parameters.filter(item => item.route === param)[0].title;
    if (title === 'Status') return title;
    return singular ? title.substring(0, title.length - 1) : title;
  };

  const getEntity = () => {
    let entity;

    parameters.forEach(parameter => {
      if (parameter.route === param) entity = parameter;
    });

    return entity;
  };

  const setNullTemp = () => {
    let tmp = {};

    getEntity().fields.forEach(field => {
      tmp[field.field] = '';
    });

    setTemp(tmp);
    setDropdownBehavior(false);
  };

  const handleNotification = () => {
    let element = document.getElementById('notification');
    const hidden = {
      transform: 'translateY(-10px)',
      opacity: 0,
      visibility: 'hidden',
    };
    const visible = {
      transform: 'translateY(0px)',
      opacity: 1,
      visibility: 'visible',
    };

    if (!notificationOpen) {
      element.animate([hidden, visible], {
        duration: 500,
        easing: 'ease',
        fill: 'forwards',
      });
    } else {
      element.animate([visible, hidden], {
        duration: 500,
        easing: 'ease',
        fill: 'forwards',
      });
    }

    setNotificationOpen(!notificationOpen);
  };

  // const getMaxItemsPerPage = () => {
  //   const windowHeight = window.innerHeight;
  //   const headerHeight = 173;
  //   const perPixel = 80;

  //   const normalizedHeight = windowHeight - headerHeight;

  //   return parseInt(normalizedHeight / perPixel) - 1;
  // };

  const getAssociations = key => {
    let values = [];

    associations.forEach(association => {
      if (association[key]) {
        association[key]
          .sort((acc, item) =>
            acc.name.toLowerCase().localeCompare(item.name.toLowerCase()),
          )
          .forEach(item => {
            values.push({ name: item.name, id: item.id });
          });
      }
    });

    return values;
  };

  const getFilterName = item => {
    let name = '';

    if (filter) {
      const keyName = Object.keys(filter);
      const filterId = filter[keyName[0]];
      const association = getAssociations(item.field).filter(
        item => item.id === filterId,
      )[0];

      if (association) {
        return { value: association.id, label: association.name };
      }
    }

    return name;
  };

  const getTableTitles = () => {
    return getEntity().fields.map((field, index) => {
      const keys = ['Atividades', 'Iniciativas'];
      let title = field.title;

      if (excludeParameterTable.includes(title)) {
        return null;
      }

      if (keys.includes(getEntity().title) && field.field === 'name') {
        title = getTitle(true);
      }

      return <th key={index}>{title}</th>;
    });
  };

  const getTableValues = () => {
    if (loading) {
      const fields = getEntity().fields;
      const pulseFields = 3;
      const width = 100 / fields.length;
      const size = { height: 20, width: `${width}%` };

      return new Array(pulseFields).fill(undefined).map((_, index) => (
        <tr key={index}>
          {new Array(fields.length).fill(undefined).map((_, index) => (
            <Item key={index} style={size}>
              <Pulse />
            </Item>
          ))}
        </tr>
      ));
    }

    if (data.length === 0 || !data) {
      return (
        <EmptyItem>
          <td colSpan={getEntity().fields.length + 1}>
            <FiInbox />
            Nenhuma informação associada à esta tabela.
          </td>
        </EmptyItem>
      );
    }

    return data.map((item, index) => (
      <tr key={index}>
        {getEntity().fields.map((parameter, index) => {
          if (excludeParameterTable.includes(parameter.title)) {
            return null;
          }

          let value = item[parameter.field];

          if (parameter.field.includes('date')) {
            value = value
              ? moment(value).add(1, 'd').format('DD MMM YYYY')
              : 'N/A';
          } else if (
            parameter.field.includes('value') ||
            parameter.field.includes('totalValue')
          ) {
            value = value
              ? (Number(value) || 0).toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })
              : 'N/A';
          } else if (parameter.field.includes('_id')) {
            const table = parameter.field.substring(
              0,
              parameter.field.length - 3,
            );

            if (item[table]) value = item[table].name;
          } else if (parameter.field.includes('executed')) {
            let percent = (value || 0) * 100;
            if (percent % 1 !== 0) {
              percent = percent.toFixed(1);
            }
            value = `${percent}%`;
          }

          const handleClick = () => {
            if (parameter.field !== 'code') return;

            history.push(`/management/${id}/activities`);

            setFilter(prevData => ({ ...prevData, initiative_id: item.id }));
          };

          return (
            <Item
              key={index}
              onClick={handleClick}
              clickable={parameter.field === 'code'}
            >
              {capitalize(value)}
            </Item>
          );
        })}
        <td style={{ cursor: 'pointer' }}>
          <BiEdit
            onClick={() => {
              setDropdownBehavior(true);
              modalRef.current?.setValue(item, 'modify');
              setTemp(item);
            }}
            style={{ color: 'rgba(255, 255, 255, 0.8)' }}
          />
        </td>
      </tr>
    ));
  };

  // const getAmount = () => {
  //   const value = 'R$ 13.539,90';

  //   return (
  //     <PanelContainer>
  //       <PanelValue>
  //         <p>Montante</p>
  //         <span>{value}</span>
  //       </PanelValue>
  //       <PanelIcon>
  //         <FaRegMoneyBillAlt />
  //       </PanelIcon>
  //     </PanelContainer>
  //   );
  // };

  const downloadFile = async name => {
    const { data, status } = await api.request(
      `plannings/${id}/${getEntity().baseroute || getEntity().route}/${
        temp.id
      }/file`,
    );

    if (status !== 200) {
      return;
    }

    const blobPdf = base64toBlob(data.file);

    const url = window.URL.createObjectURL(new Blob([blobPdf]));
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', `${name}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const getForm = () => {
    const formatDate = originalDate => {
      try {
        if (typeof originalDate === 'string' && originalDate.includes('Z')) {
          return format(add(parseISO(originalDate), { days: 1 }), 'dd/MM/yyyy');
        }
      } catch (err) {
        console.log('Error in data:', originalDate);
      }

      if (!originalDate) {
        return '';
      }

      const unix = getUnixTime(parseISO(originalDate));
      const fnsDate = fromUnixTime(unix);

      if (isValid(fnsDate)) {
        // const test = getUnixTime(fnsDate) + 86400;
        const test = getUnixTime(fnsDate);

        return format(fromUnixTime(test), 'dd/MM/yyyy');
      }

      if (originalDate.length !== 10) {
        return '';
      }

      const date = originalDate.split('-');
      if (date.length !== 3) {
        return false;
      }

      return `${date[2]}/${date[1]}/${date[0]}`;
    };

    const getInput = field => {
      const dropzoneMessages = (isDragActive, isDragReject) => {
        if (!!temp[field]) {
          return (
            <span style={{ display: 'flex', alignItems: 'center' }}>
              Documento Anexado <MdDone />
            </span>
          );
        }
        if (!isDragActive) {
          return (
            <span>
              Arraste e solte ou <label htmlFor="input">navegue</label>
            </span>
          );
        }

        if (isDragReject) {
          return <span>Arquivo não suportado</span>;
        }

        return <span>Solte o arquivo aqui</span>;
      };

      const inputs = [
        {
          include: 'value',
          component: (
            <NumberFormat
              placeholder="R$ 1.000,00"
              allowNegative={false}
              thousandSeparator="."
              decimalSeparator=","
              prefix="R$ "
              decimalScale={2}
              fixedDecimalScale
              value={Number(temp[field])}
              onValueChange={value =>
                setTemp({ ...temp, [field]: value.floatValue })
              }
            />
          ),
          type: 'input',
        },
        {
          include: 'file',
          component: (
            <Dropzone
              accept="application/pdf"
              onDropAccepted={files => {
                if (files.length !== 1) {
                  // err
                  return;
                }
                const file = files[0];
                setTemp({ ...temp, [field]: file });
              }}
            >
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragReject,
              }) => (
                <Dragdrop
                  {...getRootProps()}
                  isDragActive={isDragActive}
                  isDragReject={isDragReject}
                  isDropped={!!temp[field]}
                >
                  <input {...getInputProps()} />
                  {dropzoneMessages(isDragActive, isDragReject)}
                </Dragdrop>
              )}
            </Dropzone>
          ),
          type: 'file',
        },
        {
          include: '_id',
          component: (
            // <ReactSelect
            //   defaultValue={getFilterName(
            //     temp[field.substring(0, field.length - 3)],
            //   )}
            //   placeholder="Escolha uma opção..."
            //   components={{ NoOptionsMessage }}
            //   classNamePrefix="react-select"
            //   options={getAssociations(field).map(item => ({
            //     value: item.id,
            //     label: item.name,
            //   }))}
            //   onChange={value => setTemp({ ...temp, [field]: value.value })}
            // />
            <Dropdown
              style={{ marginTop: 10, color: 'white' }}
              backgroundColor="transparent"
              maxLength={18}
              borderColor={theme.card.border}
              defaultValue={temp[field.substring(0, field.length - 3)]?.name}
              placeholder="Escolha uma opção..."
              values={getAssociations(field)}
              behavior={dropdownBehavior}
              onClick={value => setTemp({ ...temp, [field]: value.id })}
            />
          ),
          type: 'dropdown',
        },
        {
          include: 'date',
          component: (
            <NumberFormat
              format="##/##/####"
              allowNegative={false}
              placeholder="16/11/2020"
              value={formatDate(temp[field])}
              onValueChange={value => {
                if (value.value === '') {
                  setTemp({ ...temp, [field]: '' });
                  return;
                }

                const formatValue = () => {
                  const date = value.formattedValue
                    .replaceAll(' ', 'a')
                    .split('/');
                  const validNum = date.filter(num => !isNaN(num));

                  if (date.length !== 3 || validNum.length !== 3) {
                    return false;
                  }

                  const rules = [
                    date[0].length === 2,
                    date[1].length === date[0].length,
                    date[2].length === 4,
                  ];
                  if (rules.filter(rule => !rule).length > 0) {
                    return false;
                  }

                  return `${date[2]}-${date[1]}-${date[0]}`;
                };

                const date = formatValue();
                if (date) {
                  setTemp({ ...temp, [field]: date });
                }
              }}
            />
          ),
          type: 'input',
          error: () => {
            if (temp[field] === '' || !temp[field]) {
              return false;
            }

            return !moment(temp[field]).isValid();
          },
        },
        {
          include: '',
          component: (
            <input
              style={{ width: '100%' }}
              placeholder="Digite um valor"
              type="text"
              value={temp[field] ? temp[field] : ''}
              onChange={event =>
                setTemp({ ...temp, [field]: event.target.value })
              }
            />
          ),
        },
      ];

      const components = inputs.filter(input => field.includes(input.include));

      if (components.length > 0) {
        const component = components[0];

        if (component.type === 'dropdown' || component.type === 'file') {
          return component.component;
        }

        return (
          <Input error={component.error ? component.error() : false}>
            {component.component}
          </Input>
        );
      }

      return null;
    };

    return (
      <FieldContent>
        {getEntity().fields.map((item, index) => {
          if (item.editable === false) {
            return <Fragment key={index}></Fragment>;
          }

          return (
            <Field key={index}>
              <FieldText>
                <span>{item.title}</span>
                {item.field === 'file' && temp.file && (
                  <DownloadIcon
                    onClick={() => {
                      (async () => {
                        await downloadFile(temp.name);
                      })();
                    }}
                  >
                    <BsCloudDownload />
                  </DownloadIcon>
                )}
              </FieldText>
              {getInput(item.field)}
            </Field>
          );
        })}
      </FieldContent>
    );
  };

  const deleteHandle = error => {
    if (error) {
      setError(error);
    } else {
      setError(false);
      setNotificationType('removido');
      fetchData();
    }

    setNullTemp();
    handleNotification();
  };

  const doneHandle = async () => {
    try {
      const type = modalRef.current?.getType();
      let fields = [];
      let body = [];

      getEntity().fields.forEach(item => fields.push(item.field));

      setRequestLoading(true);

      if (type === 'modify') {
        fields.forEach(field => {
          data.forEach(item => {
            if (item.id === temp.id) {
              if (item[field] !== temp[field]) {
                body = { ...body, [field]: temp[field] };
              }
            }
          });
        });
      } else {
        fields.forEach(field => {
          let finalField = field;

          if (field === 'date_start') finalField = 'dateStart';
          if (field === 'date_end') finalField = 'dateEnd';

          body = { ...body, [finalField]: temp[field] };
        });
      }

      let route = `plannings/${id}/${getEntity().baseroute || param}`;
      if (type === 'modify') {
        route += `/${temp.id}`;
      }

      const method = type === 'modify' ? 'PUT' : 'POST';
      const formData = new FormData();

      Object.keys(body).forEach(key => {
        if (body[key] === '') {
          return;
        }

        if (key === 'file') {
          formData.append(key, body[key], body[key].name);
        } else {
          formData.append(key, body[key]);
        }
      });

      delete body.executed;
      delete body.totalValue;

      const { status, error } = await api.fileRequest(
        route,
        method,
        !!temp.file ? formData : JSON.stringify(body),
      );

      const errorHandle = (status !== 200 && status !== 201) || error;
      if (!errorHandle) {
        setRequestLoading(false);
        setNotificationType(type === 'modify' ? 'atualizado' : 'adicionado');
      }

      setError(errorHandle ? { code: status, error } : false);
      handleNotification();
      setNullTemp();
    } catch (error) {
      setError({ code: null, error });
    }
  };

  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>Nenhuma opção encontrado</span>
      </components.NoOptionsMessage>
    );
  };

  const Filters = () => {
    const entity = getEntity();

    if (!entity.filter) {
      return null;
    }

    const getValues = ({ field }) => {
      const associations = getAssociations(field)
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
        .map(item => ({
          name: capitalize(item.name),
          id: item.id,
        }));

      return [{ name: 'Todos', id: 0 }, ...associations];
    };

    const SearchByCode = () => {
      let props = {
        search,
        setSearch,
        placeholder: 'Pesquisar por código MAPP',
      };

      const pages = ['Iniciativas', 'Atividades'];

      return pages.includes(entity.title) && <Search {...props} />;
    };

    return (
      <>
        <SearchByCode />
        <ListContainer>
          {entity.filter.map((item, index) => (
            <ReactSelect
              key={index}
              defaultValue={getFilterName(item)}
              placeholder={`Filtrar por ${item.name}...`}
              components={{ NoOptionsMessage }}
              classNamePrefix="react-select"
              options={getValues(item).map(item => ({
                value: item.id,
                label: item.name,
              }))}
              onChange={value => {
                let filters = filter || {};

                filters = { ...filters, [item.field]: value.value };

                for (let val in filters) {
                  if (filters.hasOwnProperty(val) && filters[val] === 0) {
                    delete filters[val];
                  }
                }
                setFilter(filters);
              }}
            />
          ))}
        </ListContainer>
        {((filter && Object.keys(filter).length > 0) || search !== '') && (
          <TotalValueCard>
            <span>
              Valor Total:{' '}
              {(Number(totalValue) || 0).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </span>
          </TotalValueCard>
        )}
      </>
    );
  };

  const getNotificationMessage = (error, type) => {
    let message;

    if (error && error.code === 406) {
      message = `Item já está em processo de homologação.`;
    } else if (error) {
      message = `Houve um erro ao processar sua solicitação. (${
        error.code || error.error || 'Internal'
      })`;
    } else {
      message = `Item ${type} com sucesso!`;
    }

    return message;
  };

  return (
    <>
      <Modal
        ref={modalRef}
        title={`${getTitle(true)}`}
        deleteUrl={`plannings/${id}/${getEntity().baseroute || param}`}
        onDelete={deleteHandle}
        onDone={doneHandle}
        onClose={setNullTemp}
        disableButton={buttonState}
      >
        {temp && getForm()}
      </Modal>
      <NotificationContainer id="notification" error={error}>
        <NotificationIcon error={error}>
          {error ? <IoAlert /> : <MdDone />}
        </NotificationIcon>
        {getNotificationMessage(error, notificationType)}
      </NotificationContainer>
      <Header
        title={getTitle()}
        subtitle="Veja todas as informações específicas da opção selecionada e realize edições."
      >
        <OptionsContainer>
          <FilterContainer>
            {!getEntity().view && (
              <Button
                disabled={loading}
                onClick={() => {
                  if (!loading) {
                    setDropdownBehavior(true);
                    modalRef.current?.setValue(null, 'add');
                  }
                }}
              >
                Adicionar {getTitle(true)}
              </Button>
            )}
            <Filters />
          </FilterContainer>
          {/* {getAmount()} */}
        </OptionsContainer>
        <TableContainer>
          <thead>
            <tr>
              {getTableTitles()}
              <th></th>
            </tr>
          </thead>
          <tbody>{getTableValues()}</tbody>
        </TableContainer>
        {/* <PaginationContainer>
          <Pagination
            count={totalItems}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            maxItemPerPage={maxItemsPerPage}
            maxChunckSize={maxChunckSize}
          />
        </PaginationContainer> */}
      </Header>
    </>
  );
};

export default withTheme(Editable);
