import styled from 'styled-components';

import { transparentize } from 'polished';

export const Pulse = styled.div`
  display: inline-block;
  height: 100%;
  width: 100%;
  background: ${props => 'linear-gradient(-90deg, ' +
    transparentize(.9, props.theme.light) + ' 0%, ' +
    transparentize(.75, props.theme.light) + ' 50%, ' +
    transparentize(.9, props.theme.light) + ' 100%)'
  };
  background-size: 400% 400%;
  border-radius: 5px;
  animation: pulse 1.5s ease-in-out infinite;

  @keyframes pulse {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;