import React, { useCallback, useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router';
import { add, isValid } from 'date-fns';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import {
  Arrow,
  CardChildren,
  CardContent,
  CardHeader,
  Container,
  Row,
} from './styles';

import Header from '../../../components/Header';
import { Pulse } from '../../../components/Skeleton';

import api from '../../../services/new-api';

const keys = {
  activity: {
    name: 'Nome',
    responsible: 'Responsável',
    date_start: 'Data de Início',
    date_end: 'Data de Término',
    value: 'Valor',
    state: 'Status',
    initiative: 'Iniciativa',
    comments: 'Observação',
  },
  initiative: {
    name: 'Nome',
    code: 'Código',
    responsible: 'Responsável',
    perspective: 'Perspectiva',
    stage: 'Estágio',
    unit: 'Unidade',
    font: 'Fonte',
    goal: 'Objetivo',
    comments: 'Observação',
  },
};

const populate = {
  activity: ['state', 'initiative'],
  initiative: ['perspective', 'stage', 'unit', 'font', 'goal'],
};

const Detail = () => {
  const history = useHistory();
  const { id } = useParams();

  const getPageType = useCallback(
    mode => {
      const type = history.location.pathname.includes('activity');

      switch (mode) {
        case 'title':
          return type ? 'Atividade' : 'Iniciativa';
        case 'route':
          return type ? 'activities' : 'initiatives';
        default:
          return type ? 'activity' : 'initiative';
      }
    },
    [history.location.pathname],
  );

  document.title = `Detalhamento de ${getPageType('title')} – Progy`;

  const [currentData, setCurrentData] = useState(null);
  const [newData, setNewData] = useState(null);
  const [loading, setLoading] = useState(true);

  if (isNaN(id)) {
    history.push('/homologation');
  }

  useEffect(() => {
    const fetchData = async () => {
      const { data, error } = await api.get({
        route: `pending-${getPageType('route')}/${id}?populate=${populate[
          getPageType()
        ].join(',')}`,
      });
      if (!error) {
        let item = {};
        for (let element in data) {
          if (element.includes('_id')) {
            element = element.substr(0, element.length - 3);

            item = { ...item, [element]: data[element]?.name };
          } else if (typeof data[element] !== 'object') {
            item = { ...item, [element]: data[element] };
          }
        }

        setNewData(item);

        const dataId = data[`${getPageType()}_id`];

        if (dataId) {
          const route = `plannings/${data.planning_id}/${getPageType(
            'route',
          )}/${dataId}?populate=${populate[getPageType()].join(',')}`;

          const response = await api.get({ route });

          if (!response.error) {
            let responseItem = {};
            for (let ele in response.data) {
              if (ele.includes('_id')) {
                ele = ele.substr(0, ele.length - 3);

                responseItem = {
                  ...responseItem,
                  [ele]: response.data[ele]?.name,
                };
              } else if (typeof data[ele] !== 'object') {
                responseItem = {
                  ...responseItem,
                  [ele]: response.data[ele],
                };
              }
            }

            setCurrentData(responseItem);
          }
        }
      }

      setLoading(false);
    };

    fetchData();
  }, [id, getPageType]);

  const getKeys = data => {
    let keys = [];
    for (let element in data) {
      if (element.includes('_id')) {
        element = element.substr(0, element.length - 3);
      }
      keys.push(element);
    }

    return keys;
  };

  const Card = ({ dataKey, array }) => {
    const title = keys[getPageType()][dataKey];
    if (!title) {
      return <div />;
    }

    let data = array[dataKey];
    if (!data) {
      return <div />;
    }

    if (isValid(parseISO(data))) {
      data = format(add(parseISO(data), { days: 1 }), 'dd/MM/yyyy');
    }

    return (
      <>
        <Row>
          <strong>{title}</strong>
          <span>{data}</span>
        </Row>
      </>
    );
  };

  const SkeletonCard = () => {
    return (
      <>
        {Object.values(keys[getPageType()]).map((_, index) => (
          <Row key={String(index)}>
            <Pulse
              style={{ width: Math.random() * (60, 120) + 60, height: 20 }}
            />
          </Row>
        ))}
      </>
    );
  };

  return (
    <Header
      title={`Detalhamento de ${getPageType('title')}`}
      subtitle={`Visualize os detalhes da ${getPageType(
        'title',
      ).toLowerCase()} anterior e atual`}
    >
      <Container>
        <CardContent>
          <CardHeader>{getPageType('title')} atual</CardHeader>
          <CardChildren>
            {loading && <SkeletonCard />}
            {!loading &&
              getKeys(currentData).map((key, index) => (
                <Card key={String(index)} dataKey={key} array={currentData} />
              ))}
          </CardChildren>
        </CardContent>
        <Arrow />
        <CardContent>
          <CardHeader>Nova {getPageType('title')}</CardHeader>
          <CardChildren>
            {loading && <SkeletonCard />}
            {!loading &&
              getKeys(newData).map((key, index) => (
                <Card key={String(index)} dataKey={key} array={newData} />
              ))}
          </CardChildren>
        </CardContent>
      </Container>
    </Header>
  );
};

export default Detail;
