import styled from 'styled-components';

import { transparentize } from 'polished';

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  background: ${props => props.theme.colors.background};
  background: ${props =>
    `linear-gradient(141deg, ${props.theme.login.background.start} 0%, ${props.theme.login.background.end} 80%)`};
`;

export const LogoContainer = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;

  img {
    height: 50px;
    width: auto;
  }

  span {
    margin-left: 10px;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 10px;
  }

  @media (max-width: 420px) {
    img {
      height: 80px;
    }

    span {
      font-size: 25px;
    }
  }
`;

export const LoginContainer = styled.div`
  background-color: ${props => props.theme.background};
  border: 1px solid ${props => props.theme.sidebar.border};
  border-radius: 5px;
  box-shadow: 0 1px 15px ${props => transparentize(0.7, props.theme.dark)};
  width: 400px;
  margin: 70px auto;

  @media (max-width: 420px) {
    width: 280px;
  }
`;

export const LoginTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0;
  color: ${props => props.theme.light};
  text-align: center;

  span {
    font-size: 13px;
    opacity: 0.5;
    font-weight: 500;
    margin-bottom: 5px;
  }

  strong {
    font-size: 22px;
    opacity: 1;
    font-weight: 600;
  }

  @media (max-width: 420px) {
    strong {
      font-size: 20px;
    }
  }
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  margin-bottom: 20px;
`;

export const FieldText = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;

  span {
    color: ${({ theme }) => theme.light};
  }

  span:last-child {
    font-weight: 300;
    opacity: 0.5;
    font-size: 14px;
    cursor: pointer;
    transition: ${props => props.theme.transition.hover};
  }

  span:last-child:hover {
    opacity: 1;
  }
`;

export const Input = styled.div`
  padding: 10px;
  height: 22px;
  border: 1px solid ${props => props.theme.sidebar.border};
  border-radius: 5px;
  transition: ${props => props.theme.transition.hover};
  margin-top: 10px;
  display: flex;
  align-items: center;

  input {
    width: 90%;
    background-color: transparent;
    border: none;
    color: ${props => props.theme.light};
    font-size: 16px;

    &:hover,
    &:focus {
      border: none;
      outline: none;
    }

    &::-webkit-input-placeholder {
      font-size: 15px;
    }
  }

  svg {
    font-size: 24px;
    color: ${props => props.theme.sidebar.border};
    cursor: pointer;
    transition: ${props => props.theme.transition.hover};

    &:hover {
      color: ${props => props.theme.light};
    }
  }

  &:hover {
    box-shadow: 0 1px 6px ${props => transparentize(0.8, props.theme.light)};
    border-color: ${props => transparentize(0.3, props.theme.light)};
  }
`;

export const Icon = styled.div`
  display: ${props => (props.show ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: ${props =>
    transparentize(0.8, props.error ? props.theme.error : props.theme.primary)};
  color: ${props => (props.error ? props.theme.error : props.theme.primary)};
  user-select: none;
  font-size: 14px;
  font-weight: 200;

  svg {
    color: ${props =>
      props.error ? props.theme.error : props.theme.primary} !important;
    user-select: none !important;
    cursor: auto !important;
  }
`;

export const Error = styled.div`
  display: ${props => (props.show ? 'flex' : 'none')};
  padding: 0 20px 20px;
  align-items: center;
  color: ${props => props.theme.error};

  svg {
    margin-right: 10px;
  }
`;
