import React, { useContext, useState } from 'react';

import {
  Field,
  FieldText,
  Input,
  Icon,
  Table
} from '../../styles';

import { Container, IconContainer, InputDate } from './styles';

import { FormContext } from '../../../../../hooks/Form';

import { BiPlus } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';

import NumberFormat from 'react-number-format';

const Fonts = () => {
  const { fonts } = useContext(FormContext);

  const [name, setName] = useState(null);
  const [code, setCode] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);
  const [value, setValue] = useState(null);
  const [otherValue, setOtherValue] = useState(null);

  const addItem = () => {
    if (!name || !code || !value) return;
    if (fonts.get instanceof Array) {
      const arr = [...fonts.get];
      const id = fonts.id + 1;

      arr.push({ id, name, code, dateEnd, value, otherValue });

      fonts.set(arr);
      fonts.setId(id);
    } else {
      fonts.set([{ id: fonts.id, name, code, dateEnd, value, otherValue }]);
    }

    setName(null);
    setCode(null);
    setDateEnd(null);
    setValue(null);
    setOtherValue(null);
  }

  const removeItem = value => {
    const arr = [...fonts.get];

    fonts.set(arr.filter(item => item.id !== value));
  }

  const getArray = () => {
    if (!fonts.get || fonts.get.length === 0) return [''];

    return fonts.get;
  }

  return (
    <div>
      <Container>
        <Field>
          <FieldText>Nome</FieldText>
          <Input>
            <input
              placeholder="Fonte"
              value={name || ''}
              onChange={event => setName(event.target.value)}
            />
          </Input>
        </Field>
        <Field>
          <FieldText>Código Orçamentário</FieldText>
          <Input>
            <input
              placeholder="1234"
              value={code || ''}
              onChange={event => {
                if (!isNaN(event.target.value)) setCode(event.target.value);
              }}
            />
          </Input>
        </Field>
      </Container>
      <Container>
        <Field>
          <FieldText>Data Final</FieldText>
          <Input style={{ width: '115%' }}>
            <InputDate
              type="date"
              placeholder="Unidade"
              value={dateEnd || ''}
              onChange={event => setDateEnd(event.target.value)}
            />
          </Input>
        </Field>
        <Field style={{ marginLeft: 72 }}>
          <FieldText>Valor</FieldText>
          <Input>
            <NumberFormat
              placeholder="R$ 15.325,30"
              allowNegative={false}
              thousandSeparator="."
              decimalSeparator=","
              prefix="R$ "
              decimalScale={2}
              fixedDecimalScale
              value={value || ''}
              onValueChange={value => setValue(value.floatValue)}
            />
          </Input>
        </Field>
      </Container>
      <Container>
        <Field style={{ width: '81%' }}>
          <FieldText>Valor de Contra-partida</FieldText>
          <Input>
            <NumberFormat
              placeholder="R$ 15.325,30"
              allowNegative={false}
              thousandSeparator="."
              decimalSeparator=","
              prefix="R$ "
              decimalScale={2}
              fixedDecimalScale
              value={otherValue || ''}
              onValueChange={value => setOtherValue(value.floatValue)}
            />
          </Input>
        </Field>
        <IconContainer>
          <Icon onClick={addItem}>
            <BiPlus />
          </Icon>
        </IconContainer>
      </Container>
      <Table>
        <table style={{ marginRight: 0 }}>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Ação</th>
            </tr>
          </thead>
          <tbody>
            {getArray().map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td>{item ? (
                  <AiOutlineDelete
                    onClick={() => removeItem(item.id)}
                  />
                ) : null}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Table>
    </div>
  );
}

export default Fonts;