import React from 'react';

import {
  Container,
  ResultContainer,
  Loader
} from './styles';

import { MdDone } from 'react-icons/md';
import { IoAlert } from 'react-icons/io5';

const Complete = ({ loading, error, errorMessage }) => {
  return (
    <Container>
      {loading && (<Loader id="loader-planning"><div /><div /><div /><div /><div /><div /><div /><div /></Loader>)}

      {!loading && (!error ? (
        <ResultContainer error={false}>
          <MdDone />
        </ResultContainer>
      ) : (
        <ResultContainer error>
          <IoAlert />
        </ResultContainer>
      ))}
      {!loading && (!error ? (
        <span>Parametrização concluída com sucesso! <br />Clique em <strong>Finalizar</strong> para sair.</span>
      ) : (
        <span>
          Houve um erro ao concluir a nova parametrização!
          <br />Clique em <strong>Anterior</strong> para retornar à criação.
          <br />Erro: {errorMessage || 'Code 500'}
        </span>
      ))}
    </Container >
  );
}

export default Complete;