import React, { useEffect, useState } from 'react';

import { Container, Selector, SelectorContainer, ReactSelect } from './styles';

import Header from '../../../components/Header';
import { components } from 'react-select';

import { api } from '../../../services';
import { useHistory } from 'react-router';

const Edition = () => {
  document.title = 'Gerenciamento – Progy';
  const [loaded, setLoaded] = useState(false);

  const [plannings, setPlannings] = useState([]);

  const history = useHistory();

  useEffect(() => {
    (async () => {
      const response = await api.request('plannings', 'GET');
      if (response.status !== 200) {
        console.log('unable to fetch plannings:', response.error);
      } else {
        const data = response.data.map(item => ({
          label: item.name,
          value: item.id,
        }));

        setPlannings(data);
      }

      setLoaded(true);
    })();
  }, []);

  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>Nenhum planejamento encontrado</span>
      </components.NoOptionsMessage>
    );
  };

  return (
    <Header
      title="Selecionar Planejamento"
      subtitle="Procure um planejamento específico e selecione-o."
      backButton={false}
    >
      <Container>
        <SelectorContainer>
          <Selector>
            <span>faça a busca pelo nome</span>
            <strong>Selecione um planejamento</strong>
            <ReactSelect
              placeholder="Escolha um planejamento..."
              components={{ NoOptionsMessage }}
              classNamePrefix="react-select"
              emptyText="Nenhum cargo encontrado"
              options={plannings}
              onChange={value => history.push(`/management/${value.value}`)}
              isDisabled={!loaded}
            />
          </Selector>
        </SelectorContainer>
      </Container>
    </Header>
  );
};

export default Edition;
