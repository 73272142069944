import React, { useContext, useState } from 'react';

import {
  Field,
  FieldText,
  Input,
  Icon,
  Table
} from '../../styles';

import { Container, IconContainer } from './styles';

import { FormContext } from '../../../../../hooks/Form';

import { BiPlus } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';

const Stages = () => {
  const { stages } = useContext(FormContext);

  const [name, setName] = useState(null);

  const addItem = () => {
    if (!name) return;
    if (stages.get instanceof Array) {
      const arr = [...stages.get];
      const id = stages.id + 1;

      arr.push({ id, name });

      stages.set(arr);
      stages.setId(id);
    } else {
      stages.set([{ id: stages.id, name }]);
    }

    setName(null);
  }

  const removeItem = value => {
    const arr = [...stages.get];

    stages.set(arr.filter(item => item.id !== value));
  }

  const getArray = () => {
    if (!stages.get || stages.get.length === 0) return [''];

    return stages.get;
  }

  return (
    <div>
      <Container>
        <Field style={{ width: '100%' }}>
          <FieldText>Nome</FieldText>
          <Input>
            <input
              placeholder="Estágio"
              value={name || ''}
              onChange={event => setName(event.target.value)}
            />
          </Input>
        </Field>
        <IconContainer>
          <Icon onClick={addItem}>
            <BiPlus />
          </Icon>
        </IconContainer>
      </Container>
      <Table>
        <table>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Ação</th>
            </tr>
          </thead>
          <tbody>
            {getArray().map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td>{item ? (
                  <AiOutlineDelete
                    onClick={() => removeItem(item.id)}
                  />
                ) : null}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Table>
    </div>
  );
}

export default Stages;