import React, { useEffect, useState } from 'react';

import { Container, Empty } from '../Home/styles';
import {
  CardContainer,
  CardHeader,
  CardButton,
  CardDivider,
  CardIcon,
  CardInfo,
  CardButtonContent,
} from './styles';

import Header from '../../components/Header';
import SkeletonCard from '../../components/Card/skeleton';

import api from '../../services/new-api';

import { FiLayers } from 'react-icons/fi';
import { AiOutlineAlert, AiOutlineInfoCircle } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const Homologation = () => {
  document.title = 'Homologação – Progy';

  const [loading, setLoading] = useState(true);
  const [declineLoading, setDeclineLoading] = useState(false);
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [data, setData] = useState(null);

  const fetchData = async () => {
    setLoading(true);

    let response = [];
    const { data: activitiesData, error: activitiesError } = await api.get({
      route: 'pending-activities',
    });
    if (!activitiesError) {
      response = [
        ...activitiesData.map(item => ({ ...item, type: 'activity' })),
      ];
    }

    const { data: initiativesData, error: initiativesError } = await api.get({
      route: 'pending-initiatives',
    });
    if (!initiativesError) {
      response = [
        ...response,
        ...initiativesData.map(item => ({ ...item, type: 'initiative' })),
      ];
    }

    setData(response);

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const headerProps = {
    title: 'Homologação',
    subtitle:
      'Realize a análise e aprovação ou rejeição das atualizações de atividades e iniciativas',
    backButton: false,
  };

  const skeletonCardData = Array(3).fill('');

  const CardComponent = ({ id, name, responsible, type }) => {
    const suffixRoute = type === 'activity' ? 'activities' : 'initiatives';

    const handleAccept = async () => {
      if (acceptLoading) {
        return;
      }

      setAcceptLoading(true);
      await api.get({ route: `pending-${suffixRoute}/accept/${id}` });
      setAcceptLoading(false);

      fetchData();
    };

    const handleDecline = async () => {
      if (declineLoading) {
        return;
      }

      setDeclineLoading(true);
      await api.get({ route: `pending-${suffixRoute}/decline/${id}` });
      setDeclineLoading(false);

      fetchData();
    };

    return (
      <CardContainer>
        <CardHeader>
          <CardIcon>
            <FiLayers />
          </CardIcon>
          <CardInfo>
            <div>
              <strong>
                {type === 'activity' ? 'Atividade' : 'Iniciativa'}
              </strong>
              <Link to={`/homologation/${type}/${id}`}>
                <AiOutlineInfoCircle />
              </Link>
            </div>
            <span>{name}</span>
            <span>{responsible}</span>
          </CardInfo>
        </CardHeader>
        <CardDivider />
        <CardButtonContent>
          <CardButton
            disabled={acceptLoading}
            onClick={handleAccept}
            color="success"
          >
            Aceitar
          </CardButton>
          <CardButton
            disabled={declineLoading}
            onClick={handleDecline}
            color="error"
          >
            Recusar
          </CardButton>
        </CardButtonContent>
      </CardContainer>
    );
  };

  const isLoading = () => loading;

  const isLoaded = () => !isLoading() && Array.isArray(data);

  const isEmpty = () => !isLoading() && (!data || data.length === 0);

  return (
    <Header {...headerProps}>
      <Container>
        {isLoading() &&
          skeletonCardData.map((_, index) => <SkeletonCard key={index} />)}

        {isLoaded() &&
          data.map((item, index) => <CardComponent key={index} {...item} />)}

        {isEmpty() && (
          <Empty>
            <AiOutlineAlert />
            Nenhuma homologação pendente
          </Empty>
        )}
      </Container>
    </Header>
  );
};

export default Homologation;
