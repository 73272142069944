import styled from 'styled-components';

import { transparentize } from 'polished';
import { Button as DefaultButton } from '../../components/Button';

export const Container = styled.div`
  margin: 1.5rem 2.5rem;

  display: flex;

  flex-direction: column;

  gap: 1.5rem;
`;

export const CardContainer = styled.div`
  padding: 0.75rem;

  display: flex;

  flex-direction: column;

  background-color: ${props => props.theme.card.background};

  border: 1px solid ${props => props.theme.card.border};
  border-radius: 8px;

  box-shadow: 0 1px 6px ${props => transparentize(0.84, props.theme.dark)};
`;

export const CardHeader = styled.div`
  margin-bottom: 1rem;

  display: flex;

  flex-direction: column;

  span {
    opacity: 0.5;
  }
`;

export const CardBody = styled.div``;

export const CardFooter = styled.div``;

export const Button = styled(DefaultButton)`
  height: 2.5rem;
  width: 5rem;

  margin-left: auto;
`;
