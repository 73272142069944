import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import cookies from 'js-cookie';

import {
  Container,
  Logo,
  Item,
  NavigationContainer,
  AccountContainer,
  MobileLogo,
  Mobile,
  Navigation,
  ItemContainer,
  MobileItem,
} from './styles';

import { auth } from '../../services';

import LogoWhite from '../../assets/logo-white.svg';
import LogoBlack from '../../assets/logo-black.svg';

import {
  AiOutlineFund,
  AiFillFund,
  AiOutlineCheckCircle,
  AiOutlineUsergroupAdd,
} from 'react-icons/ai';
import { RiMenu3Line } from 'react-icons/ri';
import { IoIosList, IoIosListBox } from 'react-icons/io';
import {
  IoLogOutOutline,
  IoCreate,
  IoCreateOutline,
  IoSettingsOutline,
} from 'react-icons/io5';
import { VscNewFile } from 'react-icons/vsc';
import { withTheme } from 'styled-components';

const Sidebar = ({ theme }) => {
  const [selectedOption, setSelectedOption] = useState(0);
  const [open, setOpen] = useState(false);
  const mobileRef = useRef();

  const location = useLocation();
  const history = useHistory();

  const comumRoutes = [
    {
      iconActive: <IoCreate />,
      iconInactive: <IoCreateOutline />,
      pathname: '/management',
      paths: ['/management', '/edition', '/entities'],
      name: 'Gerenciar planejamentos',
    },
  ];

  const userRoutes = [
    {
      iconActive: <AiFillFund />,
      iconInactive: <AiOutlineFund />,
      pathname: '/',
      name: 'Dashboard',
    },
  ];

  const adminRoutes = [
    {
      iconActive: <IoIosListBox />,
      iconInactive: <IoIosList />,
      pathname: '/',
      name: 'Planejamentos',
    },
    {
      iconActive: <VscNewFile />,
      iconInactive: <VscNewFile />,
      pathname: '/parameterization',
      paths: ['/parameterization'],
      name: 'Nova parametrização',
    },
  ];

  const routes = () =>
    cookies.get('role') === 'admin'
      ? [...adminRoutes, ...comumRoutes]
      : [...userRoutes, ...comumRoutes];

  const handleMenu = () => {
    let element = document.getElementById('mobile-sidebar');
    const hidden = {
      transform: 'translateY(10px)',
      opacity: 0,
      visibility: 'hidden',
    };
    const visible = {
      transform: 'translateY(20px)',
      opacity: 1,
      visibility: 'visible',
    };

    if (!open) {
      element.animate([hidden, visible], {
        duration: 350,
        easing: 'ease',
        fill: 'forwards',
      });
    } else {
      element.animate([visible, hidden], {
        duration: 350,
        easing: 'ease',
        fill: 'forwards',
      });
    }

    setOpen(!open);
  };

  useEffect(() => {
    routes().map((item, index) => {
      if (item.paths) {
        item.paths.forEach(item => {
          if (location.pathname.includes(item)) {
            setSelectedOption(index);
          }
        });
      } else {
        if (location.pathname.includes(item.pathname)) {
          setSelectedOption(index);
        }
      }
      return false;
    });
    //eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    const handle = event => {
      if (
        mobileRef.current &&
        !mobileRef.current.contains(event.target) &&
        open
      ) {
        handleMenu();
      }
    };

    document.addEventListener('mousedown', handle);

    return () => document.removeEventListener('mousedown', handle);
    //eslint-disable-next-line
  }, [mobileRef, open]);

  const handleHomologationButton = () => {
    history.push('/homologation');
  };

  const handleAddUserButton = () => {
    history.push('/users');
  };

  const handleSettingsButton = () => {
    history.push('/settings');
  };

  const getLogoFile = () => {
    switch (theme.logoFile) {
      case 'logo-white':
        return LogoWhite;
      case 'logo-black':
        return LogoBlack;
      default:
        return LogoWhite;
    }
  };

  return (
    <>
      <Container>
        <NavigationContainer>
          <Link to="/">
            <Logo src={getLogoFile()} style={{ heigth: '100%', width: 40 }} />
          </Link>
          {routes().map((item, index) => (
            <Link to={item.pathname} key={index}>
              <Item selected={index === selectedOption}>
                {index === selectedOption ? item.iconActive : item.iconInactive}
              </Item>
            </Link>
          ))}
        </NavigationContainer>
        <AccountContainer>
          {cookies.get('role') !== 'user' && (
            <Item>
              <AiOutlineCheckCircle onClick={handleHomologationButton} />
            </Item>
          )}
          {cookies.get('role') === 'admin' && (
            <Item>
              <AiOutlineUsergroupAdd onClick={handleAddUserButton} />
            </Item>
          )}
          <Item>
            <IoSettingsOutline onClick={handleSettingsButton} />
          </Item>
          <Item
            style={{ marginLeft: 3 }}
            onClick={() => {
              auth.logout();
              history.push('/login');
            }}
          >
            <IoLogOutOutline />
          </Item>
        </AccountContainer>
      </Container>
      <Mobile>
        <Navigation>
          <Link to="/dashboard">
            <MobileLogo>
              <img src={getLogoFile()} alt="Progy" />
              <h1>PROGY</h1>
            </MobileLogo>
          </Link>
          <RiMenu3Line onClick={handleMenu} />
        </Navigation>
        <ItemContainer id="mobile-sidebar" ref={mobileRef}>
          {routes().map((item, index) => (
            <Link to={item.pathname} key={index}>
              <MobileItem
                selected={index === selectedOption}
                onClick={handleMenu}
              >
                {index === selectedOption ? item.iconActive : item.iconInactive}
                <span>{item.name}</span>
              </MobileItem>
            </Link>
          ))}
          <MobileItem
            onClick={() => {
              auth.logout();
              history.push('/');
            }}
          >
            <IoLogOutOutline />
            <span>Sair</span>
          </MobileItem>
        </ItemContainer>
      </Mobile>
    </>
  );
};

export default withTheme(Sidebar);
