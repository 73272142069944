import { transparentize } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: ${props => props.theme.sidebar.width}px;
  background-color: ${props => props.theme.sidebar.background};
  border-right: 1px solid ${props => props.theme.sidebar.border};

  @media (max-width: 768px) {
    display: none;
    height: ${props => props.theme.sidebar.width}px;
    width: 100%;
    flex-direction: row;
    margin-top: -1px;
    z-index: 1;
  }
`;

export const NavigationContainer = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: row;
  }
`;

export const Logo = styled.img`
  margin: 30px 0;
  height: auto;
  width: 60px;
  cursor: pointer;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 25px;
  opacity: ${props => (props.selected ? 1 : 0.5)};
  transition: ${props => props.theme.transition.hover};
  cursor: pointer;
  color: ${props => props.theme.sidebar.item};
  margin: 5px 0;
  border: 1px solid transparent;
  background-color: ${props =>
    props.selected ? props.theme.background : null};
  border-radius: 5px;

  &:hover {
    opacity: 1;
    background-color: transparent;
    border-color: ${props =>
      props.selected ? props.theme.sidebar.border : null};
  }

  @media (max-width: 768px) {
    margin: 0 5px;
    font-size: 28px;
  }
`;

export const AccountContainer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
  }
`;

export const Mobile = styled.div`
  display: none;
  position: fixed;
  flex-direction: column;
  width: 100%;
  z-index: 1;
  padding: 16px 0px;
  height: 54px;
  background-color: ${props => props.theme.sidebar.background};
  border-right: 1px solid ${props => props.theme.sidebar.border};
  transition: 0.5s ease;

  @media (max-width: 768px) {
    display: flex;
  }
`;

export const Navigation = styled.div`
  padding: 0 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  svg {
    padding: 16px 0;
    font-size: 20px;
    cursor: pointer;
    color: ${props => props.theme.sidebar.item};
  }
`;

export const MobileLogo = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 54px;

  img {
    width: 50px;
    height: auto;
  }

  h1 {
    color: ${props => props.theme.light};
    margin-left: 10px;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 10px;
  }
`;

export const ItemContainer = styled.div`
  margin: 0 5px;
  width: 250px;
  align-self: flex-end;
  background-color: ${props => props.theme.sidebar.background};
  border: 1px solid ${props => props.theme.sidebar.border};
  box-shadow: 0 1px 6px ${props => transparentize(0.5, props.theme.dark)};
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
`;

export const MobileItem = styled.div`
  display: flex;
  padding: 10px 10px;
  cursor: pointer;
  align-items: center;
  color: ${props =>
    props.selected
      ? props.theme.sidebar.item
      : transparentize(0.5, props.theme.sidebar.item)};

  svg {
    font-size: 24px;
  }

  span {
    font-size: 16px;
    margin-left: 10px;
  }
`;
