export const expires = { expires: 7 * 5 * 12 * 2 }; // 2 Years

export const breakText = (text, limit) => {
  if (text && limit)
    return text.length > limit ? text.substring(0, limit) + '...' : text;
};

export const capitalize = (text = '') => {
  if (text === 'N/A') return text;

  return String(text)
    .split(' ')
    .map(item => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase())
    .join(' ');
};

export const mask = (value, pattern) => {
  let i = 0;
  const v = value.toString();

  return pattern.replace(/#/g, () => v[i++] || '');
};

export const base64toBlob = base64 => {
  const sliceSize = 1024;
  const byteCharacters = atob(base64);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; sliceIndex += 1) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; i += 1, offset += 1) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }

    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }

  return new Blob(byteArrays, { type: 'application/pdf' });
};

export const downloadBlob = (blob, fileName, extension) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.${extension}`);

  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};
