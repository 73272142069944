import styled from 'styled-components';

import { lighten, transparentize } from 'polished';

import { BsArrowRight } from 'react-icons/bs';

export const Container = styled.div`
  padding: 15px 30px 30px;

  display: flex;

  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 2.5rem;
`;

export const CardContent = styled.div`
  max-width: 35rem;
  min-width: 15rem;

  background-color: ${props => props.theme.card.background};

  border: 1px solid ${props => props.theme.card.border};
  border-radius: 5px;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 15px 10px;

  border-bottom: 1px solid ${props => props.theme.card.border};

  font-size: 18px;
  color: ${props => props.theme.light};

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 5px;

    border: 1px solid transparent;
    border-radius: 8px;

    background-color: ${props => lighten(0.05, props.theme.card.background)};

    cursor: pointer;

    transition: ${props => props.theme.transition.hover};

    &:hover {
      border-color: ${props => props.theme.card.border};

      background-color: transparent;
    }
  }
`;

export const CardChildren = styled.div`
  padding: 15px;
`;

export const Row = styled.div`
  margin-bottom: 1rem;

  display: flex;

  flex-direction: column;

  strong {
    font-weight: 500;
    color: ${props => props.theme.light};
  }

  span {
    color: ${props => transparentize(0.5, props.theme.light)};
  }
`;

export const Arrow = styled(BsArrowRight)`
  color: ${props => transparentize(0.2, props.theme.light)};
  font-size: 3.5rem;
`;
