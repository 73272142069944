import styled from 'styled-components';

import { ReactSelect as DefaultReactSelect } from '../../../components/Form/Select/styles';

export const Container = styled.div``;

export const SelectorContainer = styled.div`
  display: flex;
  margin-top: 30px;
  align-items: center;
  justify-content: center;
`;

export const Selector = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 60px;
  background-color: ${props => props.theme.card.background};
  border: 1px solid ${props => props.theme.card.border};
  border-radius: 5px;
  text-align: center;

  span {
    margin: 15px 0 5px;
    font-size: 13px;
    opacity: 0.5;
    font-weight: 500;
    text-transform: uppercase;
  }

  strong {
    margin-bottom: 20px;
    font-size: 22px;
    opacity: 1;
    font-weight: 600;
  }
`;

export const ReactSelect = styled(DefaultReactSelect)``;
