import React, { useEffect, useState } from 'react';

import {
  ModalChildren,
  ModalContainer,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '../../../components/Modal/styles';

import {
  CheckboxContainer,
  PickerContainer,
  ReportContent,
  Selector,
} from './styles';

import { Button } from '../../../components/Button';
import { components } from 'react-select';

import { api } from '../../../services';
import { downloadBlob } from '../../../utils';

import { VscChromeClose } from 'react-icons/vsc';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ptBR } from 'date-fns/locale';
registerLocale('ptBR', ptBR);

const ReportModal = ({ planningId, loading, goals, units }) => {
  // const [goalId, setGoalId] = useState(null);
  const [unitId, setUnitId] = useState(null);
  const [reportType, setReportType] = useState(null);
  const [inDownload, setInDownload] = useState(false);

  const [selected] = useState(0);
  const [delayed, setDelayed] = useState(false);
  const [isExcel, setIsExcel] = useState(false);

  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), 0, 1),
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), 11, 31),
  );

  // const [date, setDate] = useState(new Date());

  const types = [{ name: 'Secretaria', key: 'unit', value: unitId }];

  const reportTypes = ['Atividades', 'Iniciativas'];

  useEffect(() => {
    const keyListener = event => {
      if (event.key === 'Escape') {
        handleReportModal('close');
      }
    };

    document.addEventListener('keydown', keyListener);

    return () => {
      document.removeEventListener('keydown', keyListener);
    };
  }, []);

  const isButtonDisable = () => {
    if (!reportType) {
      return true;
    }

    if (inDownload) {
      return true;
    }

    return !types[selected].value;
  };

  const getReportOptions = () =>
    reportTypes.map((type, index) => ({ label: type, value: index }));

  const handleButton = async () => {
    if (!isButtonDisable()) {
      setInDownload(true);

      const filters = {
        [types[selected].key]: types[selected].value.value,
        delayed,
        period: [startDate, endDate],
      };

      const response = await api.reportFile(
        planningId,
        filters,
        reportType ? reportType.value : 0,
        isExcel,
      );
      const blob = await response.blob();
      const fileName = types[selected].value.label;

      downloadBlob(blob, fileName, isExcel ? 'xlsx' : 'pdf');

      setInDownload(false);
    }
  };

  const handleReportModal = behavior => {
    const element = document.getElementById('report-modal');
    if (!element) return;

    let isOpen = element.style.opacity === 1;
    if (behavior) isOpen = !(behavior === 'close' ? false : true);

    if (isOpen) {
      element.style.opacity = 0;
      element.style.visibility = 'hidden';
    } else {
      element.style.opacity = 1;
      element.style.visibility = 'visible';
    }
  };

  // const handleCheckbox = value => {
  //   setSelected(Number(value));
  // };

  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>{props.selectProps.emptyText}</span>
      </components.NoOptionsMessage>
    );
  };

  return (
    <ModalContainer style={{ width: '100%' }} id="report-modal">
      <ModalContent>
        <ModalHeader>
          <span>Gerar relatório</span>
          <div onClick={() => handleReportModal('close')}>
            <VscChromeClose />
          </div>
        </ModalHeader>
        <ModalChildren>
          {/* <ReportContent>
            <span>Eixo</span>
            <Selector
              placeholder="Escolha um eixo..."
              components={{ NoOptionsMessage }}
              classNamePrefix="react-select"
              emptyText="Nenhum eixo encontrado"
              options={goals.map(goal => ({
                label: goal.name,
                value: goal.id,
              }))}
              onChange={goal => {
                setGoalId(goal.value);
              }}
              isDisabled={loading}
            />
          </ReportContent> */}
          <ReportContent>
            <span>Tipo</span>
            <Selector
              placeholder="Escolha um tipo de relatório..."
              components={{ NoOptionsMessage }}
              classNamePrefix="react-select"
              emptyText="Nenhum tipo de relatório encontrado"
              options={getReportOptions()}
              onChange={type => {
                setReportType(type);
              }}
              isDisabled={loading}
            />
          </ReportContent>
          <ReportContent>
            <span>Secretaria</span>
            <Selector
              placeholder="Escolha uma secretaria..."
              components={{ NoOptionsMessage }}
              classNamePrefix="react-select"
              emptyText="Nenhuma secretaria encontrada"
              options={units.map(unit => ({
                label: unit.name,
                value: unit.id,
              }))}
              onChange={unit => {
                setUnitId(unit);
              }}
              isDisabled={loading}
            />
          </ReportContent>
          {(reportType ? reportType.value : -1) === 1 && (
            <ReportContent>
              <CheckboxContainer
                selected={isExcel}
                onClick={() => setIsExcel(!isExcel)}
              >
                <div />
                <span>Excel</span>
              </CheckboxContainer>
            </ReportContent>
          )}
          {(reportType ? reportType.value : -1) === 0 && (
            <ReportContent>
              {true && (
                <PickerContainer>
                  <p>Período</p>
                  <DatePicker
                    locale="ptBR"
                    selected={startDate}
                    onChange={dates => {
                      const [start, end] = dates;
                      setStartDate(start);
                      setEndDate(end);
                    }}
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="dd/MM/yy"
                    classname
                    selectsRange
                  />
                </PickerContainer>
              )}

              <CheckboxContainer
                selected={delayed}
                onClick={() => setDelayed(!delayed)}
              >
                <div />
                <span>Atrasadas</span>
              </CheckboxContainer>
            </ReportContent>
          )}
        </ModalChildren>
        <ModalFooter>
          <Button
            disabled={isButtonDisable()}
            style={{ width: '100%', marginTop: 25 }}
            onClick={handleButton}
          >
            Gerar
          </Button>
        </ModalFooter>
      </ModalContent>
    </ModalContainer>
  );
};

export default ReportModal;
