import React from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { Container, Item } from './styles';

const Pagination = ({
  count,
  currentPage,
  setCurrentPage,
  maxItemPerPage,
  maxChunckSize,
}) => {
  const getTotalPages = () => {
    const result = count / maxItemPerPage;

    if (!Number.isFinite(result) || isNaN(result)) return 1;

    return Number.isInteger(result) ? result : parseInt(result + 1);
  };

  const getCurrentPages = () => {
    const totalPages = getTotalPages();
    const result = [...new Array(totalPages).keys()].reduce(
      (resultArray, item, index) => {
        const chunkIndex = Math.floor(index / maxChunckSize);

        if (!resultArray[chunkIndex]) resultArray[chunkIndex] = [];

        resultArray[chunkIndex].push(item);

        return resultArray;
      },
      [],
    );

    let pages = [];
    result.forEach(item => {
      if (item.includes(currentPage - 1)) pages = item;
    });

    return pages;
  };

  return (
    count > maxItemPerPage && (
      <Container>
        <Item
          block={currentPage - 1 <= 0}
          onClick={() => {
            if (currentPage - 1 > 0) {
              setCurrentPage(currentPage - 1);
            }
          }}
        >
          <IoIosArrowBack />
        </Item>
        {getCurrentPages().map(item => (
          <Item
            key={item}
            block={item >= getTotalPages()}
            active={currentPage === item + 1}
            onClick={() => {
              if (item < getTotalPages()) {
                setCurrentPage(item + 1);
              }
            }}
          >
            <span>{item + 1}</span>
          </Item>
        ))}
        <Item
          block={currentPage + 1 > getTotalPages()}
          onClick={() => {
            if (currentPage + 1 <= getTotalPages()) {
              setCurrentPage(currentPage + 1);
            }
          }}
        >
          <IoIosArrowForward />
        </Item>
      </Container>
    )
  );
};

export default Pagination;
