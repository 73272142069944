import React, { useContext } from 'react';

import {
  Field,
  FieldText,
  Input
} from '../../styles';

import { FormContext } from '../../../../../hooks/Form';

const Planning = () => {
  const { planningName } = useContext(FormContext);

  return (
    <Field style={{ width: '100%' }}>
      <FieldText>Nome do Planejamento</FieldText>
      <Input>
        <input
          placeholder="Planejamento"
          value={planningName.get || ''}
          onChange={event => planningName.set(event.target.value)}
        />
      </Input>
    </Field>
  )
}

export default Planning;