import React from 'react';

import { withTheme } from 'styled-components';
import ReactTooltip from 'react-tooltip';

import {
  Container,
  BarContainer,
  ChartContainer,
  maxHeight,
  Title,
} from './styles';

import Pie from './Pie';
import { Pulse } from '../Skeleton';

import { capitalize } from '../../utils';

const Graphic = ({ title, type, loading, data, dataType, onClick, theme }) => {
  if (loading) {
    const skeletonProps = {
      style: {
        marginLeft: 'auto',
        marginRight: 'auto',
        height: 30,
        width: 100,
      },
    };
    return <Pulse {...skeletonProps} />;
  }

  const getData = () => {
    if (!data || data.length === 0) {
      return [];
    }

    return data;
  };

  const BarContent = ({ item, index }) => {
    const percentage = item.percentage > 100 ? 100 : item.percentage;
    const height = (maxHeight * percentage) / 100;
    const id = `${item.title} - ${index}`;

    return (
      <div>
        <BarContainer
          style={{ height }}
          data-tip
          data-for={id}
          onClick={() => onClick(item)}
        />
        <ReactTooltip
          id={id}
          effect="solid"
          border
          borderColor={theme.card.border}
        >
          {capitalize(item.title)}
          <br />
          Valor: {item.value}
        </ReactTooltip>
      </div>
    );
  };

  const BarChart = () => {
    return (
      <Container>
        <Title>
          <span>{title}</span>
        </Title>
        <ChartContainer>
          {getData().map((item, index) => (
            <BarContent key={index} item={item} index={index} />
          ))}
        </ChartContainer>
      </Container>
    );
  };

  if (type === 'pie') {
    return <Pie data={data} type={dataType} />;
  }

  return <BarChart />;
};

export default withTheme(Graphic);
