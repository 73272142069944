import React, { useContext } from 'react';

import {
  Field,
  FieldText,
  Input
} from '../../styles';

import { Container } from './styles';

import { FormContext } from '../../../../../hooks/Form';

const Institution = () => {
  const { institutionName, institutionCode } = useContext(FormContext);

  const validCode = code => {
    if (!code) return '';

    var regex = code.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
    return !regex[2] ? regex[1] : regex[1] + '.' + regex[2] + '.' + regex[3] + '/' + regex[4] + (regex[5] ? '-' + regex[5] : '');
  }

  return (
    <div>
      <Container>
        <Field>
          <FieldText>Nome</FieldText>
          <Input>
            <input
              value={institutionName.get || ''}
              placeholder="Organização"
              onChange={event => institutionName.set(event.target.value)}
            />
          </Input>
        </Field>
        <Field>
          <FieldText>CNPJ</FieldText>
          <Input>
            <input
              placeholder="12.345.678/9123-45"
              value={institutionCode.get || ''}
              onChange={event => institutionCode.set(validCode(event.target.value))}
            />
          </Input>
        </Field>
      </Container>
    </div>
  )
}

export default Institution;