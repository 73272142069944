import Input from './Input';
import * as Yup from 'yup';

const defaultSubmit = async (ref, schema, data) => {
  try {
    await schema.validate(data, { abortEarly: false });

    ref.current?.setErrors({});

    return true;
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      const errorMessages = {};

      error.inner.forEach(err => {
        errorMessages[err.path] = err.message;
      });

      ref.current?.setErrors(errorMessages);
    }
    return false;
  }
};

export { Input, defaultSubmit };
