import styled from 'styled-components';

import { darken, transparentize } from 'polished';

export const CardContainer = styled.div`
  width: 250px;
  background-color: ${props => props.theme.card.background};
  border: 1px solid ${props => props.theme.card.border};
  border-radius: 5px;
  transition: ${props => props.theme.transition.hover};
`;

export const CardHeader = styled.div`
  padding: 15px 15px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CardIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  border-radius: 5px;
  background-color: ${props =>
    props.color ? props.color : props.theme.primary};

  svg {
    font-size: 28px;
  }
`;

export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  div {
    width: 140px;
    display: flex;

    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    svg {
      padding: 3px;

      height: 20px;
      width: auto;

      background-color: ${props => darken(0.05, props.theme.card.background)};

      color: ${props => transparentize(0.4, props.theme.light)};

      border: 1px solid ${props => props.theme.card.border};
      border-radius: 5px;

      cursor: pointer;

      transition: 0.2s ease;

      &:hover {
        color: ${props => props.theme.light};
        border-color: ${props => props.theme.light};
      }
    }
  }

  strong {
    ${props => (props.skeleton ? 'width: 140px;' : null)};
    ${props => (props.skeleton ? 'height: 20px;' : null)};
    ${props => (props.skeleton ? 'margin-bottom: 2px;' : null)};
    font-size: 16px;
    font-weight: 600;
  }

  span {
    ${props => (props.skeleton ? 'width: 100px;' : null)};
    ${props => (props.skeleton ? 'height: 15px;' : null)};
    ${props => (props.skeleton ? 'margin: 2px 0;' : null)};
    font-size: 14px;
    color: ${props => transparentize(0.5, props.theme.light)};
  }
`;

export const CardButtonContent = styled.div`
  display: flex;

  flex-direction: row;
`;

export const CardButton = styled.div`
  height: 45px;
  width: 100%;

  padding: 10px 0;

  display: flex;

  align-items: center;
  justify-content: center;

  font-size: 14px;

  border-radius: 0 0 5px 5px;

  opacity: ${props => (props.disabled ? 0.3 : 1)};

  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  transition: ${props => props.theme.transition.hover};

  &:hover {
    background-color: ${props =>
      props.theme[props.color] || props.theme.primary};

    border-color: ${props => props.theme[props.color] || props.theme.primary};
    box-shadow: 0 1px 6px
      ${props =>
        transparentize(0.7, props.theme[props.color] || props.theme.primary)};
  }

  &:first-child {
    border-bottom-right-radius: 0;
  }

  &:not(:first-child) {
    border-bottom-left-radius: 0;
  }
`;

export const CardDivider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.card.border};
`;
