import React, { useEffect, useState } from 'react';

import { useHistory, useLocation, useParams } from 'react-router';

import { useTheme } from 'styled-components';

import {
  Container,
  DataCardContainer,
  DataCard,
  DataCardIcon,
  PrimaryCardTableContainer,
  CardTableHeader,
  CardTableTitle,
  Button,
  CardTableContent,
  ChartContainer,
  ChartsContainer,
  ChartHeader,
  ChartBody,
  ChartFooter,
  SubtitleBox,
} from './styles';

import { useCustomTheme } from '../../hooks/CustomTheme';

import Header from '../../components/Header';
import Graphic from '../../components/Graphic';
import { Pulse } from '../../components/Skeleton';
import ReportModal from './ReportModal';

import api from '../../services/new-api';

import { GiSupersonicArrow } from 'react-icons/gi';
import { IoIosPaper } from 'react-icons/io';
import { VscReport } from 'react-icons/vsc';
import { GoGraph } from 'react-icons/go';
import { FaMoneyCheck } from 'react-icons/fa';
import { AiOutlineNodeIndex } from 'react-icons/ai';

const baseColors = {
  background: '#f3f3f3',
  light: '#303030',
  sidebar: {
    background: '#27785B',
    border: '#ccc',
    item: '#38ad82',
  },
  card: {
    background: '#f9f9f9',
    border: 'transparent',
  },
  primary: '#319873',
  secondary: '#1A5490',
};

const Dashboard = () => {
  document.title = 'Dashboard – Progy';

  const { planningId } = useParams();
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const { generateNewStyles, resetStyles } = useCustomTheme();

  const planning = location.state ? location.state.planning : null;

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [goals, setGoals] = useState([]);
  const [units, setUnits] = useState([]);

  if (isNaN(planningId)) history.push('/');

  useEffect(() => {
    if (!true) {
      generateNewStyles(baseColors);
    }

    const fetchData = async () => {
      const { data: dashboardData, error: dashboardError } = await api.get({
        route: `plannings/${planningId}/dashboard`,
      });
      if (!dashboardError) {
        setData(dashboardData);
      }

      const { data: goalsData, error: goalsError } = await api.get({
        route: `plannings/${planningId}/goals`,
      });
      if (!goalsError) {
        setGoals(goalsData);
      }

      const { data: unitsData, error: unitsError } = await api.get({
        route: `plannings/${planningId}/units`,
      });
      if (!unitsError) {
        setUnits(unitsData);
      }

      setLoading(false);
    };
    fetchData();

    return () => {
      resetStyles();
    };
    //eslint-disable-next-line
  }, []);

  const headerProps = {
    title: 'Planejamento',
    subtitle: 'Obtenha os indicadores e detalhes do planejamento',
  };

  const primaryTableProps = {
    title: 'Indicadores',
    subtitle: 'Veja os indicadores relacionais',
    reportButtonText: 'Relatórios Gerenciais',
  };

  const singleCharts = [
    {
      title: 'Indicador de Custo',
      subtitle: 'Custo total por custo executado',
      Icon: FaMoneyCheck,
      type: 'pie',
      dataKey: 'costIndicator',
      dataType: 'currency',
      subtitles: ['Custo Executado', 'Custo Projetado'],
    },
    {
      title: `Índice IDP`,
      subtitle: `Índice de desempenho de planejamento`,
      obs: 'Considera o ano vigente da data de término',
      Icon: AiOutlineNodeIndex,
      type: 'pie',
      dataKey: 'idp',
      dataType: 'value',
      subtitles: ['Atividades Concluídas', 'Atividades Pendentes'],
    },
  ];

  const reportModalProps = { planning, planningId, loading, goals, units };

  const handleReportModal = behavior => {
    const element = document.getElementById('report-modal');
    if (!element) return;

    let isOpen = element.style.opacity === 1;
    if (behavior) isOpen = !(behavior === 'close' ? false : true);

    if (isOpen) {
      element.style.opacity = 0;
      element.style.visibility = 'hidden';
    } else {
      element.style.opacity = 1;
      element.style.visibility = 'visible';
    }
  };

  const DataCards = () => {
    let goals,
      delayeds,
      initiatives,
      dones = 0;
    if (data) {
      goals = data.totalGoals;
      delayeds = data.totalDelayed;
      initiatives = data.totalInitiatives;
      dones = data.totalInitiativesDone;
    }

    const datas = [
      {
        name: 'Objetivos\nEstratégicos',
        value: goals,
        backgroundColor: theme.cards.goals,
        Icon: GiSupersonicArrow,
      },
      {
        name: 'Total\nIniciativas',
        value: initiatives,
        backgroundColor: theme.cards.project,
        Icon: IoIosPaper,
      },
      {
        name: 'Iniciativas\nConcluídas',
        value: dones,
        backgroundColor: theme.cards.done,
        Icon: VscReport,
      },
      {
        name: 'Atividades\nAtrasadas',
        value: delayeds,
        backgroundColor: theme.cards.problems,
        Icon: VscReport,
        onClick: () => {
          history.push(`/management/${planningId}/delayed`);
        },
      },
    ];

    const skeletonProps = {
      style: { height: 50, width: 50, marginBottom: 10 },
    };

    return (
      <DataCardContainer>
        {datas.map((data, index) => (
          <DataCard key={index} {...data}>
            <div>
              <strong>
                {loading ? <Pulse {...skeletonProps} /> : data.value}
              </strong>
              <span>{data.name}</span>
            </div>
            <DataCardIcon {...data}>
              <data.Icon />
            </DataCardIcon>
          </DataCard>
        ))}
      </DataCardContainer>
    );
  };

  const PrimaryChartTable = ({ title, subtitle, reportButtonText }) => {
    let stageData = [];
    let statusData = [];

    if (data) {
      stageData = data.stagesPerInitiative;
      statusData = data.statusPerActivity;
    }

    const push = (content, route, filter) => {
      history.push({
        pathname: `/management/${planningId}/${route}`,
        state: { filter: { [filter]: content.id } },
      });
    };

    const stageProps = {
      title: 'Total de estágios por iniciativa',
      data: stageData,
      loading,
      onClick: content => push(content, 'initiatives', 'stage_id'),
    };
    const statusProps = {
      title: 'Total de status por atividade',
      data: statusData,
      loading,
      onClick: content => push(content, 'activities', 'state_id'),
    };

    return (
      <PrimaryCardTableContainer>
        <CardTableHeader>
          <CardTableTitle>
            <GoGraph />
            <div>
              <strong>{title}</strong>
              <span>{subtitle}</span>
            </div>
          </CardTableTitle>
          <Button onClick={() => handleReportModal()}>
            {reportButtonText}
          </Button>
        </CardTableHeader>
        <CardTableContent>
          <Graphic {...stageProps} />
          <Graphic {...statusProps} />
        </CardTableContent>
      </PrimaryCardTableContainer>
    );
  };

  const Charts = ({
    title,
    subtitle,
    obs,
    Icon,
    type,
    dataKey,
    dataType,
    subtitles,
  }) => {
    let chartData;
    if (data) {
      chartData = data[dataKey];
    }

    const chartProps = { title, loading, data: chartData, dataType, type };
    const defaultSubtitleColors = [theme.primary, theme.light];

    return (
      <ChartContainer>
        <ChartHeader>
          <Icon />
          <div>
            <h3>{title}</h3>
            <span>{subtitle}</span>
            {obs && (
              <span style={{ fontStyle: 'italic' }}>
                <small>{obs}</small>
              </span>
            )}
          </div>
        </ChartHeader>
        <ChartBody>
          <Graphic {...chartProps} />
        </ChartBody>
        <ChartFooter>
          {defaultSubtitleColors.map((color, index) => (
            <div key={index}>
              <SubtitleBox color={color} />
              <span>{subtitles[index]}</span>
            </div>
          ))}
        </ChartFooter>
      </ChartContainer>
    );
  };

  return (
    <>
      <ReportModal {...reportModalProps} />

      <Header {...headerProps}>
        <Container>
          <DataCards />
          <PrimaryChartTable {...primaryTableProps} />
          <ChartsContainer>
            {singleCharts.map((chart, index) => (
              <Charts key={index} {...chart} />
            ))}
          </ChartsContainer>
        </Container>
      </Header>
    </>
  );
};

export default Dashboard;
