import styled from 'styled-components';

import { transparentize } from 'polished';

export const Container = styled.div`  
  margin-left: ${({ theme }) => theme.sidebar.width}px;
  color: ${({ theme }) => theme.light};

  @media (max-width: 768px) {
    margin-left: 0;
    padding-top: ${props => props.theme.sidebar.width}px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px 30px 30px ${props => props.backButton ? 20 : 30}px;
`;

export const BackButton = styled.div`
  cursor: pointer;
  transition: ${props => props.theme.transition.hover};
  &:hover {
    opacity: .6;
  }
  svg {
    margin: 5px 5px 0 0;
    font-size: 22px;
  }
`;

export const Title = styled.h1`
  font-size: 26px;
  font-weight: 600;
`;

export const Subtitle = styled.div`
  margin: -10px 0 20px 30px;
  color: ${({ theme }) => transparentize(.5, theme.light)};
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.sidebar.border};
`;