import React, { useEffect, useState } from 'react';
import cookies from 'js-cookie';
import { useHistory, useParams } from 'react-router';

import { Container, Empty } from './styles';

import Header from '../../components/Header';

import { api } from '../../services';

import SkeletonCard from '../../components/Card/skeleton';
import Card from '../../components/Card';

import { FiLayers } from 'react-icons/fi';
import { AiOutlineAlert } from 'react-icons/ai';

const Home = () => {
  document.title = 'Planejamentos – Progy';
  const params = useParams();
  const [plannings, setPlannings] = useState(null);
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    const token = cookies.get('token') || params.token;

    api.getManyWithToken('plannings', token).then(response => {
      const { data, error } = response;

      if (!error) {
        setPlannings(data);
        setLoading(false);
      }
    });
    //eslint-disable-next-line
  }, []);

  return (
    <Header
      title="Planejamentos"
      subtitle="Visualize seus planejamentos"
      backButton={false}
    >
      <Container>
        {loading &&
          Array(3)
            .fill(3)
            .map((_, index) => <SkeletonCard key={index} />)}
        {plannings &&
          plannings.map((item, index) => (
            <Card
              key={index}
              icon={<FiLayers />}
              title={item.name}
              description={item.institution.name}
              code={item.cnpj}
              onClick={() =>
                history.push({
                  pathname: `/${item.id}`,
                  state: { planning: item },
                })
              }
            />
          ))}
        {!loading && !plannings && (
          <Empty>
            <AiOutlineAlert />
            {cookies.get('role') === 'admin'
              ? 'Você ainda não iniciou nenhum planejamento.'
              : 'O seu planejamento ainda não foi iniciado.'}
          </Empty>
        )}
      </Container>
    </Header>
  );
};

export default Home;
