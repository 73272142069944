import styled from 'styled-components';

import { transparentize } from 'polished';

export const Container = styled.div`
  padding: 15px 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Empty = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${props => transparentize(0.5, props.theme.light)};
  user-select: none;

  svg {
    font-size: 22px;
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;

    svg {
      font-size: 32px;
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`;
