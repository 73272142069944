import React from 'react';

import GlobalStyle from './styles/global';
import Routes from './routes';
import { CustomThemeProvider } from './hooks/CustomTheme';

const App = () => {
  return (
    <CustomThemeProvider>
      <GlobalStyle />
      <Routes />
    </CustomThemeProvider>
  );
};

export default App;
