import React from 'react';

import { Container } from './styles';

import Header from '../../../../components/Header';
import Card from '../../../../components/Card';

import { FiLayers } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router';

const List = () => {
  document.title = 'Gerenciamento – Progy';
  const history = useHistory();
  const { id } = useParams();
  const types = [
    { title: 'Perspectivas', color: '#0e5ea2', route: 'perspectives' },
    { title: 'Estágios', color: '#609651', route: 'stages' },
    { title: 'Unidades', color: '#af8113', route: 'units' },
    { title: 'Fontes', color: '#944b1b', route: 'fonts' },
    { title: 'Objetivos', color: '#8e74b5', route: 'goals' },
    { title: 'Status', color: '#08787b', route: 'states' },
    { title: 'Tipo de Mapp', color: '#24283B', route: 'mapps' },
  ];

  if (isNaN(id)) history.push('/management');

  return (
    <Header
      title="Parametrizações"
      subtitle="Escolha uma parametrização para visualizar e editar"
    >
      <Container>
        {types.map((item, index) => (
          <Card
            key={index}
            icon={<FiLayers />}
            title={item.title}
            description={''}
            color={item.color}
            buttonText="Ver Existentes"
            onClick={() => history.push(`/management/${id}/${item.route}`)}
          />
        ))}
      </Container>
    </Header>
  );
};

export default List;
