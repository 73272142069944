import React, { useContext, useState } from 'react';

import {
  Field,
  FieldText,
  Input,
  Icon,
  Table
} from '../../styles';

import { Container, IconContainer } from './styles';

import { FormContext } from '../../../../../hooks/Form';

import { BiPlus } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';

const Units = () => {
  const { units } = useContext(FormContext);

  const [name, setName] = useState(null);
  const [code, setCode] = useState(null);

  const addItem = () => {
    if (!name || !code) return;
    if (units.get instanceof Array) {
      const arr = [...units.get];
      const id = units.id + 1;

      arr.push({ id, name, code });

      units.set(arr);
      units.setId(id);
    } else {
      units.set([{ id: units.id, name, code }]);
    }

    setName(null);
    setCode(null);
  }

  const removeItem = value => {
    const arr = [...units.get];

    units.set(arr.filter(item => item.id !== value));
  }

  const getArray = () => {
    if (!units.get || units.get.length === 0) return [''];

    return units.get;
  }

  return (
    <div>
      <Container>
        <Field>
          <FieldText>Nome</FieldText>
          <Input>
            <input
              placeholder="Unidade"
              value={name || ''}
              onChange={event => setName(event.target.value)}
            />
          </Input>
        </Field>
        <Field>
          <FieldText>Código Orçamentário</FieldText>
          <Input>
            <input
              placeholder="1234"
              value={code || ''}
              onChange={event => {
                if (!isNaN(event.target.value)) {
                  setCode(event.target.value)
                }
              }}
            />
          </Input>
        </Field>
        <IconContainer>
          <Icon onClick={addItem}>
            <BiPlus />
          </Icon>
        </IconContainer>
      </Container>
      <Table>
        <table style={{ marginRight: 0 }}>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Ação</th>
            </tr>
          </thead>
          <tbody>
            {getArray().map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td>{item ? (
                  <AiOutlineDelete
                    onClick={() => removeItem(item.id)}
                  />
                ) : null}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Table>
    </div>
  );
}

export default Units;