import styled from 'styled-components';

export const Container = styled.div`
  padding: 15px 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;