import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import cookies from 'js-cookie';

import Sidebar from '../components/Sidebar';

import {
  Login,
  Home,
  Management,
  Dashboard,
  Parameterization,
  Complete,
  Edition,
  EditionList,
  Editable,
  Settings,
  Homologation,
  HomologationDetail,
  Users,
} from '../pages';

import { FormProvider } from '../hooks/Form';

const Routes = () => {
  const LoginRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        !cookies.get('token') ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  );

  const PrivateRoute = ({ children, ...rest }) => (
    <Route
      {...rest}
      render={({ location }) =>
        cookies.get('token') ? (
          children
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: location } }} />
        )
      }
    />
  );

  const AdminRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        cookies.get('role') !== 'user' && cookies.get('token') ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  );

  return (
    <BrowserRouter>
      <Switch>
        <LoginRoute exact path="/login" component={Login} />
        <PrivateRoute path="/">
          <Sidebar />
          <Switch>
            <Route exact path="/" component={Home} />
            <AdminRoute exact path="/homologation" component={Homologation} />
            <AdminRoute
              exact
              path="/homologation/activity/:id"
              component={HomologationDetail}
            />
            <AdminRoute
              exact
              path="/homologation/initiative/:id"
              component={HomologationDetail}
            />
            <AdminRoute exact path="/users" component={Users} />
            <Route exact path="/settings" component={Settings} />
            <Route exact path="/management" component={Edition} />
            <Route exact path="/management/:id" component={Management} />
            <Route
              exact
              path="/management/:id/params"
              component={EditionList}
            />
            <Route exact path="/management/:id/:param" component={Editable} />
            <Route
              exact
              path="/parameterization"
              component={() => (
                <FormProvider>
                  <Parameterization />
                </FormProvider>
              )}
            />
            <Route
              exact
              path="/parameterization/complete"
              component={Complete}
            />
            <Route path="/:planningId" component={Dashboard} />
          </Switch>
        </PrivateRoute>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
