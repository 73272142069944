import styled, { css } from 'styled-components';

import { darken, transparentize } from 'polished';

export const Container = styled.div``;

const DisabledInput = css`
  opacity: 0.5;

  cursor: not-allowed;
`;

export const InputContainer = styled.div`
  margin-top: 0.5rem;
  padding: 0.625rem;

  height: 1.375rem;
  max-width: 30rem;

  display: flex;

  align-items: center;

  background-color: ${props => darken(0.01, props.theme.card.background)};

  border: 1px solid
    ${props => (props.error ? props.theme.error : props.theme.card.border)};
  border-radius: 0.3125rem;

  ${props => props.disabled && DisabledInput};

  transition: ${props => props.theme.transition.hover};

  &:hover {
    border-color: ${props => transparentize(0.3, props.theme.light)};

    box-shadow: 0 1px 6px ${props => transparentize(0.8, props.theme.light)};
  }

  input {
    width: 100%;

    background-color: transparent;

    border: none;

    color: ${props => props.theme.light};
    font-size: 1rem;

    &:hover,
    &:focus {
      border: none;
      outline: none;
    }

    &::-webkit-input-placeholder {
      font-size: 0.9375rem;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  @media (max-width: 768px) {
    max-width: unset;
  }
`;

export const ErrorContainer = styled.div`
  margin: 0.25rem 0 0.5rem 0;

  span {
    color: ${props => props.theme.error};
  }
`;
