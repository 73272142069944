import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const InputDate = styled.input`
  &::-webkit-input-placeholder {
    font-size: 15px;
    font-family: 'Inter', sans-serif;
  }

  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
    filter: invert(.3);
    transition: ${props => props.theme.transition.hover};

    &:hover {
      filter: invert(.7);
    }
  }

  &::-webkit-outer-spin-button, ::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;