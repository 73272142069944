import cookies from 'js-cookie';

const url =
  process.env.REACT_APP_PROTOCOL + '://' + process.env.REACT_APP_API_URL;
const token = cookies.get('token');

const reportFile = (planningId, body, type, isExcel = false) => {
  let reportUrl = `${url}/plannings/${planningId}/report`;
  if (type !== 0) {
    reportUrl += '/initiative';
  }

  if (isExcel) {
    reportUrl = reportUrl.replace('report', 'sheet');
  }

  return fetch(reportUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
};

const request = (route, method, personalToken = token, body, filter) => {
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + personalToken,
    },
  };

  if (body) options.body = JSON.stringify(body);

  return fetch(`${url}/${route}${filter ? `?${filter}` : ''}`, options)
    .then(response =>
      response.json().then(data => ({
        status: response.status,
        data: data.data,
        total: data.total,
      })),
    )
    .catch(error => ({ error: error.message }));
};

const fileRequest = (route, method, body) => {
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  if (typeof body === 'string') {
    headers['Content-Type'] = 'application/json';
  }

  return fetch(`${url}/${route}`, {
    method,
    headers,
    body,
  })
    .then(response =>
      response.json().then(data => ({
        status: response.status,
        data: data.data,
        total: data.total,
      })),
    )
    .catch(error => ({ error: error.message }));
};

const getMany = route => request(`${route}`, 'GET');

const getManyWithToken = (route, token) => request(`${route}`, 'GET', token);

const getManyWithId = (route, id) => request(`plannings/${id}/${route}`, 'GET');

const getOne = (route, planning, id) =>
  request(`plannings/${planning}/${route}/${id}`, 'GET');

const createPlanning = body => request('form', 'POST', undefined, body);

const update = (route, body) => request(route, 'PUT', undefined, body);

const api = {
  getMany,
  getManyWithToken,
  getManyWithId,
  getOne,
  createPlanning,
  update,
  request,
  fileRequest,
  reportFile,
};

export default api;
