import React from 'react';

import { withTheme } from 'styled-components';
import { transparentize } from 'polished';

import { Pie as DefaultPie } from 'react-chartjs-2';

const Pie = ({ data, type, theme }) => {
  const datasets = [
    {
      data, // from db
      backgroundColor: [
        transparentize(0.8, theme.primary),
        transparentize(0.8, theme.light),
      ],
      borderColor: [theme.primary, theme.light],
      borderWidth: 1,
      offset: 4,
    },
  ];

  const callbacks = {
    label: data => {
      const value = data.parsed;
      const total = data.dataset.data.reduce((a, b) => a + b, 0);

      const percentage = `${((value / total) * 100).toFixed(2)}%`;
      const currency = value.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      });

      return [type === 'currency' ? currency : value, percentage];
    },
  };

  const options = {
    responsive: true,
    layout: { padding: 10 },
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks,
        borderColor: theme.card.border,
        borderWidth: 1,
        displayColors: false,
      },
    },
    animations: false,
  };

  const pieProps = {
    data: { datasets },
    options,
  };

  return <DefaultPie {...pieProps} />;
};

export default withTheme(Pie);
