import styled, { css } from 'styled-components';

import { darken, lighten, transparentize } from 'polished';

import { Button as DefaultButton } from '../../../../components/Button';

import { ReactSelect as DefaultReactSelect } from '../../../../components/Form/Select/styles';

export const NotificationContainer = styled.div`
  visibility: hidden;
  opacity: 0;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  left: 100px;
  bottom: 30px;
  padding: 15px 20px;
  background-color: ${props =>
    lighten(0.3, props.error ? props.theme.error : props.theme.primary)};
  border: 1px solid
    ${props => (props.error ? props.theme.error : props.theme.primary)};
  border-radius: 15px;
`;

export const NotificationIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props =>
    props.error ? props.theme.error : props.theme.primary};
  border-radius: 15px;
  padding: 5px;
  margin-right: 10px;

  svg {
    color: ${props => props.theme.light};
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin: 15px 40px 0 10px;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Button = styled(DefaultButton)`
  font-size: 14px;
  padding: 12px 15px;
  margin-right: 0px;
  height: unset;
`;

export const PanelContainer = styled.div`
  display: flex;
  flex-direction: row;

  margin-bottom: 15px;

  border: 1px solid ${props => props.theme.card.border};
  border-radius: 5px;

  background-color: ${props => props.theme.card.background};
  padding: 10px 15px;
`;

export const PanelValue = styled.div`
  display: flex;
  flex-direction: column !important;

  span {
    font-size: 14px;
    font-weight: 600;
  }

  p {
    color: ${props => transparentize(0.5, props.theme.light)};
  }
`;

export const PanelIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 5px 10px;
  margin-left: 10px;

  border-radius: 5px;
  background-color: #70982e;

  svg {
    font-size: 24px;
  }
`;

export const TableContainer = styled.table`
  overflow: hidden;

  border: 1px solid ${props => props.theme.card.border};
  border-radius: 8px;
  border-spacing: 0;

  margin-left: 30px;
  width: calc(100% - 70px);
  height: 100px;

  margin-bottom: 1rem; // only when pagination is disabled

  thead {
    background-color: ${props => props.theme.card.background};
  }

  tbody {
    tr {
      transition: 0.2s ease;

      &:hover {
        background-color: ${props => transparentize(0.9, props.theme.light)};
      }
    }
  }

  td {
    padding: 15px 5px;
    border-top: 1px solid ${props => props.theme.card.border};
    border-radius: 0px;

    svg {
      color: ${props => props.theme.light} !important;
    }
  }

  th {
    padding: 15px 5px;

    text-align: left;
    text-transform: uppercase;

    font-weight: 500;
    font-size: 14px;
    color: ${props => transparentize(0.4, props.theme.light)};
  }
`;

export const Item = styled.td`
  padding: 15px 10px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  transition: ${props => props.theme.transition.hover};
  color: ${props => transparentize(0.2, props.theme.light)};

  cursor: ${props => (props.clickable ? 'pointer' : 'default')};

  ${props =>
    props.clickable &&
    css`
      &:hover {
        text-decoration: underline;
      }
    `}
`;

export const EmptyItem = styled.tr`
  font-size: 14px;
  font-weight: 400;
  color: ${props => transparentize(0.2, props.theme.light)};

  svg {
    margin-right: 10px;
    font-size: 15px;
  }

  td {
    text-align: center;
  }
`;

export const PaginationContainer = styled.div`
  margin: 10px 40px 30px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const FieldContent = styled.div`
  display: flex;
  flex-wrap: wrap;

  div {
    flex: 40%;
  }
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  margin-bottom: 20px;
`;

export const FieldText = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;

  span {
    color: ${({ theme }) => theme.light};
  }
`;

export const Input = styled.div`
  display: flex;
  align-items: center;

  margin-top: 10px;
  padding: 10px;

  height: 22px;

  border: 1px solid
    ${props =>
      props.error
        ? transparentize(0.5, props.theme.error)
        : props.theme.card.border};
  border-radius: 5px;

  color: ${props => props.theme.light};

  background-color: ${props =>
    props.error ? transparentize(0.98, props.theme.error) : 'transparent'};

  transition: ${props => props.theme.transition.hover};

  input {
    width: 90%;
    background-color: transparent;
    border: none;
    color: ${props => props.theme.light};
    font-size: 16px;
    &:hover,
    &:focus {
      border: none;
      outline: none;
    }
    &::-webkit-input-placeholder {
      font-size: 15px;
    }
  }
  &:hover {
    box-shadow: 0 1px 6px
      ${props =>
        transparentize(
          0.8,
          props.error ? props.theme.error : props.theme.light,
        )};
    border-color: ${props =>
      transparentize(0.3, props.error ? props.theme.error : props.theme.light)};
  }
`;

const dragActive = css`
  border-color: ${props => props.theme.success};

  background-color: ${props => transparentize(0.95, props.theme.success)};
`;

const dragReject = css`
  border-color: ${props => props.theme.error};

  background-color: ${props => transparentize(0.95, props.theme.error)};
`;

const dropped = css`
  color: ${props => props.theme.light};
`;

export const Dragdrop = styled.div.attrs({
  className: 'dropzone',
})`
  padding: 0.3rem;

  display: flex;

  flex-direction: row;
  align-items: center;
  justify-content: center;

  border: 1px dashed ${props => props.theme.card.border};
  border-radius: 0.5rem;

  font-size: 0.875rem;
  font-weight: 400;
  color: ${props => transparentize(0.7, props.theme.light)};
  text-transform: unset;

  user-select: none;

  cursor: pointer;

  label {
    cursor: pointer;

    font-weight: 500;
    text-decoration: underline;
    color: ${props => props.theme.primary};
  }

  transition: 0.2s ease;

  ${props => props.isDragActive && dragActive};
  ${props => props.isDragReject && dragReject};
  ${props => props.isDropped && dropped};

  &:hover {
    border-color: ${props => props.theme.light};

    background-color: ${props => transparentize(0.95, props.theme.light)};
  }
`;

export const DownloadIcon = styled.span`
  color: ${props => props.theme.light};

  font-size: 18px;

  cursor: pointer;

  transition: 0.2s ease;

  &:hover {
    color: ${props => darken(0.05, props.theme.light)};
  }
`;

export const ListContainer = styled.div`
  margin: 0 0 2.5rem 1rem;

  display: flex;

  flex-direction: row;
  align-items: center;

  gap: 0.5rem;
`;

export const ReactSelect = styled(DefaultReactSelect)`
  margin: 0 !important;
  width: 15rem !important;

  .react-select__input-container {
    height: 2rem !important;
  }
`;

export const TotalValueCard = styled.div`
  margin-left: 1rem;
  padding: 0 0.5rem;

  height: 2.5rem;

  display: flex;
  align-items: center;

  background-color: ${props => darken(0.01, props.theme.card.background)};
  border: 1px solid ${props => props.theme.card.border};
  border-radius: 5px;

  span {
    font-size: 0.85rem;
  }
`;
