import { transparentize } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 15px 30px 30px;

  display: flex;
  flex-direction: column;

  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const DataCardContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;

    gap: 0.75rem;
  }
`;

const Card = styled.div`
  display: flex;

  background-color: ${props => props.theme.card.background};

  border: 1px solid ${props => props.theme.card.border};
  border-radius: 8px;

  box-shadow: 0 1px 6px ${props => transparentize(0.84, props.theme.dark)};
`;

export const DataCard = styled(Card)`
  padding: 1.35rem;

  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  gap: 1.5rem;

  cursor: ${props => (props.onClick ? 'pointer' : 'unset')};

  div {
    &:first-child {
      display: flex;

      flex-direction: column;
      align-items: flex-start;

      strong {
        font-size: 2.625rem;
      }

      span {
        font-size: ${props => (props.name.includes('\n') ? 0.75 : 1)}rem;
        white-space: pre-line;

        opacity: 0.5;
      }
    }
  }

  @media (max-width: 848px) {
    gap: 0;
  }

  @media (max-width: 768px) {
    width: calc(100% - 1.875rem);
  }
`;

export const DataCardIcon = styled.div`
  padding: 1rem;

  display: flex;

  align-items: center;
  justify-content: center;

  background-color: ${props => props.backgroundColor};

  border-radius: 0.5rem;

  svg {
    font-size: 2rem;
    color: white;
  }
`;

export const PrimaryCardTableContainer = styled(Card)`
  padding: 1.5rem;

  display: flex;

  flex-direction: column;

  @media (max-width: 768px) {
    width: calc(100% - 1.875rem);
  }
`;

export const CardTableHeader = styled.div`
  width: 100%;

  display: flex;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const CardTableTitle = styled.div`
  display: flex;

  flex-direction: row;
  align-items: center;

  gap: 1rem;

  svg {
    font-size: 3rem;
    color: ${props => props.theme.secondary || props.theme.light};

    opacity: 0.65;
  }

  div {
    display: flex;

    flex-direction: column;
    align-items: flex-start;

    strong {
      font-size: 1.25rem;
    }

    span {
      opacity: 0.5;
    }
  }

  @media (max-width: 768px) {
    width: calc(100% - 0.5rem);
  }
`;

export const Button = styled.div`
  margin-left: auto;
  padding: 0.75rem;

  display: flex;

  align-items: center;
  justify-content: center;

  background-color: ${props => props.theme.secondary || props.theme.primary};

  border-radius: 0.5rem;

  color: white;
  font-size: 0.875rem;

  cursor: pointer;

  transition: 0.2s ease;

  &:hover {
    box-shadow: 0 1px 6px
      ${props => props.theme.secondary || props.theme.primary};
  }

  @media (max-width: 768px) {
    margin: 1rem 0;

    width: calc(100% - 1.875rem);
  }
`;

export const CardTableContent = styled.div`
  margin-top: 0.75rem;

  display: flex;

  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  gap: 3.5rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ChartsContainer = styled.div`
  display: flex;

  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ChartContainer = styled(Card)`
  display: flex;

  flex: 40%;
  flex-direction: column;
`;

export const ChartHeader = styled.div`
  padding: 0.75rem;

  display: flex;

  flex-direction: row;
  align-items: center;

  svg {
    padding: 0.5rem;

    font-size: 2.5rem;
    color: ${props => props.theme.secondary || props.theme.light};

    opacity: 0.65;
  }

  div {
    margin-left: 0.75rem;

    display: flex;

    flex-direction: column;
    align-items: flex-start;

    h3 {
      font-size: 1.25rem;
    }

    span {
      opacity: 0.5;
    }
  }
`;

export const ChartBody = styled.div`
  margin: 0 auto;

  max-width: 15rem;
`;

export const ChartFooter = styled.div`
  margin-top: 1.25rem;
  padding: 1.25rem;

  display: flex;

  flex-direction: column;

  gap: 0.5rem;

  div {
    display: flex;

    flex-direction: row;
    align-items: center;

    gap: 0.75rem;
  }
`;

export const SubtitleBox = styled.div`
  height: 0.625rem;
  width: 1.875rem;

  background-color: ${props => transparentize(0.8, props.color)};

  border: 1.5px solid ${props => props.color};
`;
