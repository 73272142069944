import styled from 'styled-components';

import { ReactSelect as DefaultReactSelect } from '../../../components/Form/Select/styles';

import { darken, transparentize } from 'polished';

export const ReportContainer = styled.div`
  width: 100%;

  div {
    margin: -10px 0 30px 0;
    background-color: ${props => transparentize(0.8, props.theme.light)};
    border: 1px solid ${props => transparentize(0.99, props.theme.light)};

    &:hover {
      background-color: ${props => transparentize(0.95, props.theme.light)};
      border-color: ${props => transparentize(0.8, props.theme.light)};

      box-shadow: unset;
    }
  }
`;

export const ReportContent = styled.div`
  padding: 10px 20px;

  color: ${props => props.theme.light};

  .react-datepicker-popper {
    background-color: ${props => props.theme.card.background};

    border: 1px solid ${props => props.theme.card.border};
    border-radius: 8px;

    box-shadow: 0 1px 6px ${props => transparentize(0.84, props.theme.dark)};
  }

  .react-datepicker__header {
    background-color: ${props => props.theme.background};
  }

  .react-datepicker__current-month {
    font-weight: 500;
    text-transform: capitalize;
  }

  .react-datepicker__day-names {
    text-transform: capitalize;
  }

  .react-datepicker__day--in-range {
    background-color: ${props => props.theme.primary} !important;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;

  flex-direction: row;

  align-items: center;

  gap: 0.5rem;

  cursor: pointer;

  div {
    height: 10px;
    width: 10px;

    background-color: ${props =>
      props.selected ? props.theme.primary : 'transparent'};

    border: 1px solid ${props => props.theme.card.border};
    border-radius: 0.25rem;

    transition: 0.2s ease;
  }

  &:not(:first-child) {
    margin-top: 0.5rem;
  }
`;

export const Selector = styled(DefaultReactSelect)`
  min-width: 300px;
`;

export const PickerContainer = styled.div`
  margin: -0.5rem 0 1rem;

  p {
    margin-bottom: 0.5rem;
  }

  .react-datepicker__input-container {
    input {
      padding: 0.5rem;
      width: 93%;

      background-color: ${props => darken(0.01, props.theme.card.background)};
      border: 1px solid
        ${props => (props.error ? props.theme.error : props.theme.card.border)};
      border-radius: 0.3125rem;

      font-size: 0.85rem;

      cursor: pointer;

      &:focus {
        outline: none;
      }
    }
  }
`;
