import React, { useEffect, useRef } from 'react';

import { useField } from '@unform/core';
import { Container, ErrorContainer, InputContainer } from './styles';

const Input = ({ name, disabled, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, clearError, error } =
    useField(name);

  useEffect(() => {
    registerField({ name: fieldName, ref: inputRef.current, path: 'value' });
  }, [fieldName, registerField]);

  const containerProps = { error, disabled };
  const inputProps = {
    ref: inputRef,
    defaultValue,
    disabled,
    onFocus: clearError,
    ...rest,
  };

  return (
    <Container>
      <InputContainer {...containerProps}>
        <input {...inputProps} />
      </InputContainer>
      <ErrorContainer>
        <span>{error}</span>
      </ErrorContainer>
    </Container>
  );
};

export default Input;
