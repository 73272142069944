import { transparentize } from 'polished';
import styled from 'styled-components';

export const maxHeight = 200;

export const Container = styled.div`
  width: 100%;
`;

export const Title = styled.div`
  margin: 0.5rem 0;
  padding: 0 0.25rem;

  border-left: 3px solid ${props => transparentize(0.5, props.theme.light)};
`;

export const ChartContainer = styled.div`
  width: 100%;
  height: ${maxHeight}px;

  position: relative;

  display: flex;

  flex-direction: row;
  align-items: flex-end;

  border-bottom: 1px solid ${props => props.theme.light};
`;

export const BarContainer = styled.div`
  margin-left: 10px;

  width: 20px;

  background-color: ${props => transparentize(0.8, props.theme.primary)};

  border: 1px solid ${props => props.theme.primary};
  border-bottom-width: 0;

  cursor: pointer;

  &:last-child {
    margin-right: 10px;
  }
`;
