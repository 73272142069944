import React, { useRef, useEffect, useState, useCallback } from 'react';

import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import api from '../../services/new-api';

import {
  Button,
  CardBody,
  CardContainer,
  CardFooter,
  CardHeader,
  Container,
} from '../Settings/styles';
import { TableContainer } from './styles';
import { PaginationContainer } from '../Management/Edition/Editable/styles';

import Header from '../../components/Header';
import { defaultSubmit, Input } from '../../components/Form';
import Select from '../../components/Form/Select';
import Pagination from '../../components/Pagination';

const Users = () => {
  document.title = 'Usuários – Progy';

  const ref = useRef(null);

  const [loading, setLoading] = useState(true);
  const [institutions, setInstitutions] = useState([]);
  const [units, setUnits] = useState([]);
  const [users, setUsers] = useState([]);

  const [tempInstitution, setTempInstitution] = useState(null);

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const perPage = 5;
  const maxChunckSize = 3;

  const headerProps = {
    title: 'Usuários',
    subtitle: 'Visualize e edite os usuários',
    backButton: false,
  };

  const roles = {
    admin: 'Administrador',
    subadmin: 'Gestor',
    manager: 'Secretário',
    user: 'Técnico',
  };

  const roleOptions = Object.keys(roles).map(name => ({
    value: name,
    label: roles[name],
  }));

  const fetchUser = useCallback(async () => {
    let route = 'users';
    route += `?pagination=${JSON.stringify({
      perPage,
      page: currentPage - 1,
    })}`;

    const { data, error, total } = await api.get({ route });
    if (error) {
      console.warn(`Error on fetch users: ${error}`);
      return;
    }

    if (total !== 0 && totalItems === 0) {
      setTotalItems(total);
    }

    setUsers(data);
  }, [currentPage, totalItems]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser, currentPage]);

  useEffect(() => {
    const fetchInstitution = async () => {
      const { data: response, error } = await api.get({ route: 'institution' });
      if (error) {
        console.warn(`Error on fetch institution: ${error}`);
        return;
      }

      const formatData = response.map(item => ({
        value: item.id,
        label: item.name,
      }));

      setInstitutions(formatData);
      setLoading(false);
    };

    fetchUser();
    fetchInstitution();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!tempInstitution) {
      return;
    }

    const fetchUnits = async () => {
      const { data: plannings, err } = await api.get({ route: 'plannings' });

      if (err) {
        toast.error(`Não foi possível carregar os planejamentos (${err})`);
        setUnits([]);
        return;
      }

      if (plannings.length === 0) {
        toast.error(`Nenhum planejamento foi encontrado.`);
        setUnits([]);
        return;
      }

      const planning = plannings.find(
        item => item.institution_id === tempInstitution.value,
      );

      if (!planning) {
        toast.error(
          'Não foi possível encontrar o planejamento da instituição.',
        );
        setUnits([]);
        return;
      }

      const { data, error } = await api.get({
        route: `plannings/${planning.id}/units`,
      });

      if (error) {
        toast.error(`Erro ao carregar as unidades (${error})`);
        setUnits([]);
        return;
      }

      const formatData = data.map(item => ({
        value: item.id,
        label: item.name,
      }));

      setUnits(formatData);
    };

    fetchUnits();
  }, [tempInstitution]);

  const handleAddUserSubmit = async data => {
    const schema = Yup.object().shape({
      name: Yup.string().required('O nome do usuário é obrigatório'),
      email: Yup.string()
        .email('E-mail inválido')
        .required('O e-mail do usuário é obrigatório'),
      password: Yup.string()
        .min(6, 'A senha precisa ter ao menos 6 caracteres')
        .required('A senha do usuário é obrigatória'),
      institution_id: Yup.number()
        .typeError('A instituição do usuário é obrigatória')
        .required('A instituição do usuário é obrigatória'),
      unit_id: Yup.number().nullable(true),
      role: Yup.string()
        .required('O cargo do usuário é obrigatório')
        .nullable(),
    });

    const response = await defaultSubmit(ref, schema, data);

    if (response) {
      const promise = new Promise(async (resolve, reject) => {
        const { error } = await api.post({
          route: 'users',
          body: data,
        });

        if (error) {
          reject();
        }

        setTempInstitution(null);
        resolve();
      });

      toast.promise(promise, {
        success: `Usuário ${data.name.split(' ')[0]} criado com sucesso`,
        pending: 'Criando usuário...',
        error: 'Houve um erro ao criar o usuário',
      });
    }
  };

  return (
    <Header {...headerProps}>
      <ToastContainer autoClose={2500} />
      <Container>
        {!loading && (
          <CardContainer>
            <CardHeader>
              <h3>Adicionar Usuário</h3>
              <span>Adicione um novo usuário</span>
            </CardHeader>
            <Form ref={ref} onSubmit={handleAddUserSubmit}>
              <CardBody>
                <span>Nome</span>
                <Input name="name" />

                <span>E-mail</span>
                <Input name="email" type="email" />

                <span>Senha</span>
                <Input name="password" type="password" />

                <span>Instituição</span>
                <Select
                  name="institution_id"
                  placeholder="Selecionar instituição..."
                  emptyText="Nenhuma instituição encontrada"
                  options={institutions}
                  onChange={value => {
                    setTempInstitution(value);
                  }}
                />

                <span>Unidade</span>
                <Select
                  name="unit_id"
                  placeholder="Selecionar unidade..."
                  emptyText="Nenhuma unidade encontrada"
                  options={units}
                />

                <span>Cargo</span>
                <Select
                  name="role"
                  placeholder="Selecionar cargo..."
                  emptyText="Nenhum cargo encontrado"
                  options={roleOptions}
                />
              </CardBody>
              <CardFooter>
                <Button onClick={() => ref.current?.submitForm()}>
                  Adicionar
                </Button>
              </CardFooter>
            </Form>
          </CardContainer>
        )}

        <h3>Lista de usuários</h3>
        {!loading && (
          <>
            <TableContainer>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>E-mail</th>
                  <th>Cargo</th>
                  <th>Instituição</th>
                  <th>Unidade</th>
                </tr>
              </thead>
              <tbody>
                {users.map(user => (
                  <tr key={user.id}>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{roles[user.role]}</td>
                    <td>{user.institution ? user.institution.name : 'N/A'}</td>
                    <td>{user.unit ? user.unit.name : 'N/A'}</td>
                  </tr>
                ))}
              </tbody>
            </TableContainer>
            <PaginationContainer>
              <Pagination
                count={totalItems}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                maxItemPerPage={perPage}
                maxChunckSize={maxChunckSize}
              />
            </PaginationContainer>
          </>
        )}
      </Container>
    </Header>
  );
};

export default Users;
