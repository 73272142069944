import { withTheme } from 'styled-components';

import { Container, Header, Icon, Info, Button, Divider } from './styles';

import { breakText } from '../../utils';

const Simple = ({
  icon,
  color,
  title,
  description = 'Não definido',
  code,
  onClick,
  buttonText,
  theme,
}) => (
  <Container>
    <Header>
      <Icon color={color}>{icon}</Icon>
      <Info>
        <strong>{breakText(title, 14)}</strong>
        <span>{breakText(description, 14)}</span>
        <span>{code ? breakText(code, 14) : ''}</span>
      </Info>
    </Header>
    <Divider color={theme.card.border} />
    <Button onClick={onClick}>
      {buttonText ? buttonText : 'Ver Detalhes'}
    </Button>
  </Container>
);

export default withTheme(Simple);
