const parameters = [
  {
    title: 'Perspectivas',
    route: 'perspectives',
    fields: [{ title: 'Nome', field: 'name' }],
  },
  {
    title: 'Estágios',
    route: 'stages',
    fields: [{ title: 'Nome', field: 'name' }],
  },
  {
    title: 'Tipo de MAPP',
    route: 'mapps',
    fields: [{ title: 'Nome', field: 'name' }],
  },
  {
    title: 'Unidades',
    route: 'units',
    fields: [
      { title: 'Nome', field: 'name' },
      { title: 'Código Orçamentário', field: 'code' },
    ],
  },
  {
    title: 'Fontes',
    route: 'fonts',
    fields: [
      { title: 'Nome', field: 'name' },
      { title: 'Código MAPP', field: 'code' },
      { title: 'Data', field: 'date', allowNull: true },
      { title: 'Valor', field: 'value' },
      {
        title: 'Valor de Contra-Partida',
        field: 'other_value',
        allowNull: true,
      },
    ],
  },
  {
    title: 'Objetivos',
    route: 'goals',
    fields: [{ title: 'Nome', field: 'name' }],
  },
  {
    title: 'Status',
    route: 'states',
    fields: [{ title: 'Nome', field: 'name' }],
  },
  {
    title: 'Iniciativas',
    route: 'initiatives',
    fields: [
      { title: 'Objetivo', field: 'goal_id', route: 'goals', view: false },
      {
        title: 'Perspectiva',
        field: 'perspective_id',
        route: 'perspectives',
        view: false,
      },
      { title: 'Nome', field: 'name' },
      {
        title: 'Total de Atividades',
        field: 'totalActivities',
        editable: false,
        allowNull: true,
      },
      {
        title: '% de Atividades Executadas',
        field: 'executed',
        editable: false,
        allowNull: true,
      },
      { title: 'Código', field: 'code' },
      { title: 'Código Orçamentário', field: 'budget_code' },
      { title: 'Responsável', field: 'responsible' },
      { title: 'Unidade', field: 'unit_id', route: 'units' },
      { title: 'Estágio', field: 'stage_id', route: 'stages' },
      { title: 'Fonte', field: 'font_id', route: 'fonts' },
      { title: 'Tipo de MAPP', field: 'mapp_id', route: 'mapps' },
      {
        title: 'Valor Total',
        field: 'totalValue',
        editable: false,
        allowNull: true,
      },
      { title: 'Arquivo', field: 'file', allowNull: true },
      { title: 'Observação', field: 'comments', allowNull: true },
    ],
    filter: [{ field: 'stage_id', name: 'estágio' }],
  },
  {
    title: 'Atividades',
    route: 'activities',
    fields: [
      { title: 'Iniciativa', field: 'initiative_id', route: 'initiatives' },
      { title: 'Nome', field: 'name' },
      { title: 'Responsável', field: 'responsible' },
      { title: 'Data de Início', field: 'date_start' },
      { title: 'Data de Término', field: 'date_end' },
      { title: 'Valor', field: 'value', allowNull: true },
      { title: 'Status', field: 'state_id', route: 'states' },
      { title: 'Arquivo', field: 'file', allowNull: true },
      { title: 'Observação', field: 'comments', allowNull: true },
    ],
    filter: [
      { field: 'initiative_id', name: 'iniciativa' },
      { field: 'state_id', name: 'status' },
    ],
  },
  {
    title: 'Atividades Atrasadas',
    route: 'delayed',
    baseroute: 'activities',
    view: true,
    fields: [
      { title: 'Iniciativa', field: 'initiative_id', route: 'initiatives' },
      { title: 'Nome', field: 'name' },
      { title: 'Responsável', field: 'responsible' },
      { title: 'Data de Início', field: 'date_start' },
      { title: 'Data de Término', field: 'date_end' },
      { title: 'Valor', field: 'value', allowNull: true },
      { title: 'Status', field: 'state_id', route: 'states' },
    ],
  },
];

export default parameters;
