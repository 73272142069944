import React, { useState, createContext } from 'react';
import cookies from 'js-cookie';

import { expires } from '../utils';

const FormContext = createContext({});

const FormProvider = ({ children }) => {
  const form = cookies.get('form') ? JSON.parse(cookies.get('form')) : {};

  const validateField = (field, ret) => {
    if (Object.keys(form).length === 0) return ret;

    return form[field] || ret;
  }

  const [step, setStep] = useState(validateField('step', 0));

  const [institutionName, setInstitutionName] = useState(validateField('institutionName', null));
  const [institutionCode, setInstitutionCode] = useState(validateField('institutionCode', null));
  const [planningName, setPlanningName] = useState(validateField('planningName', null));
  const [planningSector, setPlanningSector] = useState(validateField('planningSector', null));

  const [perspectives, setPerspectives] = useState(validateField('perspectives', null));
  const [perspectiveId, setPerspectiveId] = useState(validateField('perspectiveId', 0));

  const [stages, setStages] = useState(validateField('stages', null));
  const [stageId, setStageId] = useState(validateField('stageId', 0));

  const [units, setUnits] = useState(validateField('units', null));
  const [unitId, setUnitId] = useState(validateField('unitId', 0));

  const [fonts, setFonts] = useState(validateField('fonts', null));
  const [fontId, setFontId] = useState(validateField('fontId', 0));

  const [goals, setGoals] = useState(validateField('goals', null));
  const [goalId, setGoalId] = useState(validateField('goalId', 0));

  const [states, setStates] = useState(validateField('states', null));
  const [stateId, setStateId] = useState(validateField('stateId', 0));

  const set = (param, setState, field) => {
    form[field] = param;
    setState(param);
    cookies.set('form', form, expires);
  }

  const values = {
    step: {
      get: step,
      set: param => set(param, setStep, 'step')
    },
    institutionName: {
      get: institutionName,
      set: param => set(param, setInstitutionName, 'institutionName'),
      validation: () => institutionName ? true : false
    },
    institutionCode: {
      get: institutionCode,
      set: param => set(param, setInstitutionCode, 'institutionCode'),
      validation: () => institutionCode && institutionCode.length === 18
    },
    planningName: {
      get: planningName,
      set: param => set(param, setPlanningName, 'planningName'),
      validation: () => planningName ? true : false
    },
    planningSector: {
      get: planningSector,
      set: param => set(param, setPlanningSector, 'planningSector'),
      validation: () => planningSector ? true : false
    },
    perspectives: {
      get: perspectives,
      set: param => set(param, setPerspectives, 'perspectives'),
      validation: () => perspectives && perspectives.length > 0,
      id: perspectiveId,
      setId: param => set(param, setPerspectiveId, 'perspectiveId')
    },
    stages: {
      get: stages,
      set: param => set(param, setStages, 'stages'),
      validation: () => stages && stages.length > 0,
      id: stageId,
      setId: param => set(param, setStageId, 'stageId')
    },
    units: {
      get: units,
      set: param => set(param, setUnits, 'units'),
      validation: () => units && units.length > 0,
      id: unitId,
      setId: param => set(param, setUnitId, 'unitId')
    },
    fonts: {
      get: fonts,
      set: param => set(param, setFonts, 'fonts'),
      validation: () => fonts && fonts.length > 0,
      id: fontId,
      setId: param => set(param, setFontId, 'fontId')
    },
    goals: {
      get: goals,
      set: param => set(param, setGoals, 'goals'),
      validation: () => goals && goals.length > 0,
      id: goalId,
      setId: param => set(param, setGoalId, 'goldId')
    },
    states: {
      get: states,
      set: param => set(param, setStates, 'states'),
      validation: () => states && states.length > 0,
      id: stateId,
      setId: param => set(param, setStateId, 'stateId')
    }
  }

  return (
    <FormContext.Provider value={values}>
      {children}
    </FormContext.Provider>
  )
}

export {
  FormContext,
  FormProvider
}