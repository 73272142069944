import styled from 'styled-components';

import { lighten, transparentize } from 'polished';

export const Container = styled.div`
  display: flex;
  margin: 50px 0px;
  width: 100%;
  justify-content: center;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 50px;
  border-radius: 5px;
  background-color: ${props => props.theme.card.background};
  border: 1px solid ${props => props.theme.card.border};
  max-width: 300px;

  @media (max-width: 430px) {
    max-width: 200px;
  }
`;

export const CardIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
  background-color: ${props =>
    transparentize(0.5, lighten(0.1, props.theme.card.background))};
  margin-bottom: 20px;

  svg {
    font-size: 24px;
    color: ${props => props.theme.light};
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  strong {
    color: ${props => props.theme.light};
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
  }

  span {
    color: ${props => transparentize(0.5, props.theme.light)};
    text-align: center;
    font-size: 14px;
    margin-bottom: 20px;
  }
`;

export const CardButton = styled.div`
  padding: 10px 30px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.button.text};
  cursor: pointer;
  transition: ${props => props.theme.transition.hover};

  &:hover {
    box-shadow: 0 1px 6px ${props => props.theme.primary};
  }
`;
