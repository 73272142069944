import styled from 'styled-components';

import { transparentize, tint, darken } from 'polished';

export const Container = styled.div`
  position: relative;
  height: 50px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid ${props => props.borderColor || props.theme.card.border};
  background-color: ${props =>
    props.backgroundColor || darken(0.01, props.theme.card.background)};
  border-radius: 5px;
  transition: ${props => props.theme.transition.hover};
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    box-shadow: 0 1px 6px ${props => transparentize(0.8, props.theme.light)};
    border-color: ${props => transparentize(0.3, props.theme.light)};
  }

  span {
    color: ${props => transparentize(0.7, props.theme.light)};
    font-size: 15px;
    font-weight: 300;
  }

  strong {
    color: ${props => props.theme.light};
    font-size: 15px;
    font-weight: 300;
  }
`;

export const Selector = styled.div`
  position: absolute;
  z-index: 2;
  margin-top: 5px;
  opacity: 0;
  visibility: hidden;
  width: 100%;
  max-height: 168px;
  overflow-y: auto;
  border: 1px solid ${props => props.theme.card.border};
  border-radius: 5px;
  background-color: ${props => darken(0.01, props.theme.card.background)};
  transition: ${props => props.theme.transition.hover};
  box-shadow: 0 1px 6px ${props => transparentize(0.6, props.theme.dark)};

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Item = styled.div`
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  transition: ${props => props.theme.transition.hover};

  color: ${props => props.theme.light};

  &:hover {
    background-color: ${props => tint(0.1, props.theme.background)};
  }
`;
