import cookies from 'js-cookie';

const {
  REACT_APP_PROTOCOL,
  REACT_APP_API_HOST,
  REACT_APP_API_URL,
  REACT_APP_API_PORT,
} = process.env;
const token = cookies.get('token');

const getHost = route => {
  const protocol = REACT_APP_PROTOCOL;
  let host = REACT_APP_API_HOST || REACT_APP_API_URL;
  let port = REACT_APP_API_PORT || undefined;

  if (host.substr(host.length - 1) === '/') {
    host = host.substring(0, host.length - 1);
  }

  if (!port && host === 'localhost') {
    port = 3333;
  }

  const buildHost = () => {
    let result = host;
    if (port && port.length > 0) {
      result += `:${port}`;
    }

    return result;
  };

  return `${protocol}://${buildHost()}/${route}`;
};

const getHeaders = () => {
  let base = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  return base;
};

const withoutBody = async (props, method) => {
  try {
    const { route } = props;

    const response = await fetch(getHost(route), {
      method,
      headers: getHeaders(),
    });

    return response.json();
  } catch (error) {
    return { error: error.message };
  }
};

const withBody = async (props, type) => {
  try {
    const { route, body } = props;

    const response = await fetch(getHost(route), {
      method: type,
      headers: getHeaders(),
      body: JSON.stringify(body),
    });

    return response.json();
  } catch (error) {
    return { error: error.message };
  }
};

const api = {
  get: async props => withoutBody(props, 'GET'),
  post: async props => withBody(props, 'POST'),
  put: async props => withBody(props, 'PUT'),
  delete: async props => withoutBody(props, 'DELETE'),
  getHost,
};

export default api;
