import React, { useContext, useState } from 'react';

import Header from '../../../components/Header';

import { FormContext } from '../../../hooks/Form';

import {
  Container,
  FormContainer,
  FormContent,
  HeaderContainer,
  StepContainer,
  StepDivider,
  StepItem,
  Button,
  ButtonContainer,
} from './styles';

import {
  Institution,
  Planning,
  Perspectives,
  Stages,
  Units,
  Fonts,
  Goals,
  States,
} from './Forms';

import { api } from '../../../services';
import Complete from './Complete';
import { useHistory } from 'react-router';
import cookies from 'js-cookie';

const Parameterization = () => {
  document.title = 'Nova Parametrização – Progy';
  const [loading, setLoading] = useState(true);
  const [finish, setFinish] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const {
    step,
    institutionName,
    institutionCode,
    planningName,
    perspectives,
    stages,
    units,
    fonts,
    goals,
    states,
  } = useContext(FormContext);
  const history = useHistory();
  const forms = [
    {
      title: 'informar organização',
      subtitle: 'adicionar detalhes da organização',
      component: <Institution />,
      fields: [institutionName, institutionCode],
      keys: ['institutionName', 'institutionCode'],
    },
    {
      title: 'informar planejamento estratégico',
      subtitle: 'adicionar detalhes do planejamento estratégico',
      component: <Planning />,
      fields: [planningName],
      keys: ['planningName'],
    },
    {
      title: 'informar perspectivas organizacionais',
      subtitle: 'adicionar detalhes das perspectivas organizacionais',
      component: <Perspectives />,
      fields: [perspectives],
      keys: ['perspectives'],
    },
    {
      title: 'informar estágios de execução',
      subtitle: 'adicionar detalhes dos estágios de execução',
      component: <Stages />,
      fields: [stages],
      keys: ['stages'],
    },
    {
      title: 'informar unidades',
      subtitle: 'adicionar detalhes das unidades',
      component: <Units />,
      fields: [units],
      keys: ['units'],
    },
    {
      title: 'informar fontes de recursos',
      subtitle: 'adicionar detalhes das fontes de recursos',
      component: <Fonts />,
      fields: [fonts],
      keys: ['fonts'],
    },
    {
      title: 'informar objetivos estratégicos',
      subtitle: 'adicionar detalhes dos objetivos estratégicos',
      component: <Goals />,
      fields: [goals],
      keys: ['goals'],
    },
    {
      title: 'informar status',
      subtitle: 'adicionar detalhes dos status',
      component: <States />,
      fields: [states],
      keys: ['states'],
    },
  ];

  const validation = fields =>
    fields.filter(item => item.validation()).length !== fields.length;

  const previousButtonDisabled = () => step.get === 0 || (finish && !error);
  const nextButtonDisabled = () => {
    if (finish) {
      return error || loading;
    } else {
      return validation(forms[step.get] ? forms[step.get].fields : []);
    }
  };

  return (
    <Header
      title="Nova Parametrização"
      subtitle="Crie uma nova parametrização para o planejamento, definindo os campos abaixo."
      backButton={false}
    >
      <Container>
        <StepContainer>
          {Array.from(Array(forms.length).keys()).map(item => [
            <StepItem key={String(item)} active={item <= step.get}>
              {item + 1}
            </StepItem>,
            <StepDivider key={'div-' + item} active={item <= step.get - 1} />,
          ])}
        </StepContainer>
        <FormContainer>
          <HeaderContainer>
            <span>
              {!finish
                ? forms[step.get]
                  ? forms[step.get].subtitle
                  : 'Subtitle'
                : 'Processando parametrização'}
            </span>
            <strong>
              {!finish
                ? forms[step.get]
                  ? forms[step.get].title
                  : 'Title'
                : 'Finalização'}
            </strong>
          </HeaderContainer>
          <FormContent>
            {!finish ? (
              forms[step.get] ? (
                forms[step.get].component
              ) : (
                'Component'
              )
            ) : (
              <Complete
                loading={loading}
                error={error}
                errorMessage={errorMessage}
              />
            )}
          </FormContent>
          <ButtonContainer>
            <Button
              disabled={previousButtonDisabled()}
              onClick={() => {
                if (!previousButtonDisabled()) {
                  if (error) {
                    setLoading(true);
                    setFinish(false);
                    setError(false);
                  } else {
                    step.set(step.get - 1);
                  }
                }
              }}
            >
              Anterior
            </Button>
            <Button
              disabled={nextButtonDisabled()}
              onClick={() => {
                if (!nextButtonDisabled()) {
                  if (step.get === forms.length - 1) {
                    if (finish && !error) {
                      history.push('/');
                      return;
                    }

                    setFinish(true);
                    api
                      .createPlanning({
                        institutionName,
                        institutionCode,
                        planningName,
                        perspectives,
                        stages,
                        units,
                        fonts,
                        goals,
                        states,
                      })
                      .then(response => {
                        const { status, error } = response;

                        setLoading(false);
                        if (error || status !== 201) {
                          setError(true);
                          setErrorMessage(error);
                        } else {
                          cookies.remove('form');
                        }
                      })
                      .catch(_ => setError(true));
                  } else {
                    step.set(step.get + 1);
                  }
                }
              }}
            >
              {forms.length === step.get + 1 ? 'Finalizar' : 'Próximo'}
            </Button>
          </ButtonContainer>
        </FormContainer>
      </Container>
    </Header>
  );
};

export default Parameterization;
