import styled from 'styled-components';

import { shade, transparentize } from 'polished';

export const Button = styled.div`
  margin: 0 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  background-color: ${props =>
    props.disabled
      ? shade(0.4, props.color ? props.color : props.theme.primary)
      : props.color
      ? props.color
      : props.theme.primary};
  color: ${props =>
    props.disabled
      ? shade(0.4, props.theme.button.text)
      : props.theme.button.text};
  border-radius: 5px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  user-select: none;
  transition: ${props => props.theme.transition.hover};

  &:hover {
    ${props =>
      !props.disabled &&
      `box-shadow: 0 1px 6px ${transparentize(
        0.1,
        props.color ? props.color : props.theme.primary,
      )}`}
  }
`;
